<template>
  <v-container>

    <NavBar v-if="userData.tipoPropiedad == 'casa'" :step="5" />
    <NavBar v-if="userData.tipoPropiedad == 'departamento'" :step="6" />

    <Titulo></Titulo>

    <br>
    <br>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header v-if="screen > 800" style="max-width: 38%; margin: auto;">
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Beneficios
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-header v-if="screen < 800">
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Beneficios
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <br><br>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">

                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C; margin-top: -3%;">
                    Ingresa tus datos</h3>
                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Son necesarios para completar la póliza del seguro</h4>

                  <br>

                  <v-text-field v-model="userData.name" maxlength="51" @change="change()" :rules="nameRules" disabled
                    label="Nombre" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.surname" maxlength="51" @change="change()" :rules="surnameRules"
                    disabled label="Apellido" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.email" maxlength="51" @change="change()" :rules="emailRules" disabled
                    label="Email" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.phoneNumber" maxlength="8" :counter="8" @change="change()"
                    :rules="phoneRules" prefix="+569" label="Teléfono" required solo filled
                    class="v-textField"></v-text-field>

                  <br>

                  <v-row v-if="screen < 800">

                    <v-col>
                      <v-select :rules="monthRules" class="date-selector-movil" :items="months" v-model="userData.month"
                        :loading="loadingMonths" v-on:change="getDays()" item-text="name" label="Mes" return-object
                        required solo filled></v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="dayRules" class="date-selector-movil" :items="days" v-model="userData.day"
                        :loading="loadingDays" :disabled="loadingDays || userData.month === null" item-text="name"
                        label="Día" return-object required solo filled>
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="yearRules" class="date-selector-movil" value="2022" :items="years"
                        v-model="userData.year" item-text="name" label="Año" return-object required solo
                        filled></v-select>
                    </v-col>

                  </v-row>

                  <v-row v-else>

                    <v-col>
                      <v-select :rules="monthRules" class="date-selector" :items="months" v-model="userData.month"
                        :loading="loadingMonths" v-on:change="getDays()" item-text="name" label="Mes" return-object
                        required solo filled></v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="dayRules" class="date-selector" :items="days" v-model="userData.day"
                        :loading="loadingDays" :disabled="loadingDays || userData.month === null" item-text="name"
                        label="Día" return-object required solo filled>
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="yearRules" class="date-selector" value="2022" :items="years"
                        v-model="userData.year" item-text="name" label="Año" @change="changeBisiesto()" return-object
                        required solo filled></v-select>
                    </v-col>

                  </v-row>

                  <br>

                  <v-text-field v-model.lazy="newRut" maxlength="13" @change="change()" :rules="rutRules"
                    label="Ingresa tu RUT ( sin punto y con guión)" required solo filled class="v-textField"
                    @input="changeRut"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.serialNumber" maxlength="10" @change="change()"
                    :rules="serialNumberRules" label="Ingresa el número de Documento / Serie" required solo filled
                    class="v-textField">
                  </v-text-field>

                  <br>

                  <h3
                    style="color: var(--AD-Primario-1, #2D387C); text-align: center; font-family: Arial, Helvetica, sans-serif; font-size: 20px;
                             font-style: normal; font-weight: 700; line-height: 27.5px; /* 137.5% */ letter-spacing: -0.2px;">
                    ¿Tu dirección es la misma que la de la propiedad asegurada?
                  </h3>

                  <v-row class="radioButton">
                    <v-radio-group v-model="userData.flagAddress" inline style="">
                      <v-col>
                        Si
                        <v-radio @change="setDataAddress(true), change()" label="" value="radio-1"></v-radio>
                      </v-col>
                      <v-col>
                        No
                        <v-radio @change="setDataAddress(false), change()" label="" value="radio-2"></v-radio>
                      </v-col>
                    </v-radio-group>
                  </v-row>

                  <br>

                  <v-text-field v-model="userData.address" maxlength="51" @change="change()" :rules="addressRules"
                    label="Ingresa tu dirección" required solo filled class="v-textField"></v-text-field>

                  <br>

                  <div class="number-address">
                    <v-text-field v-model="userData.addressNumber" maxlength="51" @change="change()"
                      :rules="addressNumberRules" label="Ingresa el número de la calle" required solo filled
                      class="v-textField"></v-text-field>

                    <div v-if="userData.tipoPropiedad === 'casa' && homeData.condominio != 'No'"
                      style="padding-left: 10px;">
                      <v-text-field v-model="userData.addressCondo" maxlength="51"
                        label="Ingresa tu número de condominio" required solo @change="change()" filled
                        :rules="isAddressCondoValid ? addressCondoRules : []" class="v-textField"></v-text-field>
                    </div>
                    <div v-else-if="userData.tipoPropiedad === 'departamento'" style="padding-left: 10px;">
                      <v-text-field v-model="userData.addressDepto" maxlength="51" label="Ingresa tu número de Depto"
                        required solo @change="change()" filled :rules="isAddressDeptoValid ? addressDeptoRules : []"
                        class="v-textField"></v-text-field>
                    </div>  
                  </div>

                  <br>


                  <v-select :items="regions" v-model="userData.region" @change="change()" :rules="regionRules"
                    :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object solo filled
                    class="v-textField"></v-select>

                  <br>

                  <v-select :items="communes" v-model="userData.commune" :rules="communeRules" @change="change()"
                    :loading="loadingCommunes" item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object solo filled class="v-textField">
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>
                  </div>

                </v-col>

              </div>

              <!-- <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn text @click="back()"> Volver </v-btn>
      </v-row> -->
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>
import CountryService from "@/services/country.service.js";
import RutUtils from "@/utils/rut.utils.js";

import swal from 'sweetalert';
const moment = require('moment');
import CryptoJS from 'crypto-js';

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    isAddressCondoValid: false,
    isAddressDeptoValid: false,
    stepperColor: '#516EB3',
    screen: window.screen.width,

    userData: {
      flagAddress: false,
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      addressNumber: null,
      addressDepto: null,
      addressCondo: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      tipoPropiedad: null,
      calidadPersona: null,
      recomendacion: null,
      day: null,
      month: null,
      year: null,
    },
    homeData: {
      direccion: null,
      addressHome: null,
      addressNumberHome: null,
      communeHome: null,
      communeHomeCode: null,
      regionHome: null,
      regionHomeCode: null,
      condominio: null,
      nCondominio: null,
      requisitos: null,
      material: null,
      country: null,
      center: { lat: -33.4489, lng: -70.6693, },
      nDptoHome: null,
      nPisoHome: null,
      estructuraUF: null,
      contenidoUF: null,
      priceCLP: null,
      priceUF: null,
      couponId: null,
      infoBenef: {
        incendioEstructura: false,
        incendioContenido: false,
        sismoEstructura: false,
        sismoContenido: false,
        roboContenido: false,
        gastosMedicos: false,
        asistenciaMedica: false,
        seguroMascota: false,
      },
      valores: {
        valorIncendioEstructura: null,
        valorIncendioContenido: null,
        valorSismoEstructura: null,
        valorSismoContenido: null,
        valorRoboContenido: null,
        valorGastosMedicos: 1000,
        valorAsistenciaMedica: 1000,
        valorSeguroMascota: 1000,
        valorAsistenciaHogar: 1000,

        valorIncendioEstructuraUF: 0,
        valorIncendioContenidoUF: 0,
        valorSismoEstructuraUF: 0,
        valorSismoContenidoUF: 0,
        valorRoboContenidoUF: 0,
        valorGastosMedicosUF: 0,
        valorAsistenciaMedicaUF: 0,
        valorSeguroMascotaUF: 0,
        valorAsistenciaHogarUF: 0,
      },
      valoresUf: {
        estructuraInput: 1000,
        estructuraSlider: 1000,

        contenidoInput: 0,
        contenidoSlider: 0,
      },
      discount: {
        active: false,
        value: 1,
      }
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },

    e1: 4,
    vista: false,

    loadingCommunes: false,
    loadingRegions: false,

    loadingDays: false,
    loadingMonths: false,


    month: null,
    day: null,
    year: null,
    months: [],
    days: [],
    years: [],
    valid: false,

    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',

    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      // (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "Debes ingresar tu dirección",
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\s\-']+$/.test(v) || "Ingrese solo números y letras" // Permite guio y apostrofe
    ],
    addressNumberRules: [
      (v) => !!v || "Debes ingresar un número de calle",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    addressCondoRules: [
      (v) => !!v || "Debes ingresar tu número de condominio",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      // (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "Debes ingresar tu dirección",
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\s\-']+$/.test(v) || "Ingrese solo números y letras" // Permite guio y apostrofe
    ],
    addressDeptoRules: [
      (v) => !!v || "Debes ingresar tu número de departamento",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      // (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "Debes ingresar tu dirección",
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\s\-']+$/.test(v) || "Ingrese solo números y letras" // Permite guio y apostrofe
    ],
    regionRules: [(v) => !!v || "Ingresa la region en la que vives",],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives",],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
      (v) => /^[0-9kK.-]+$/.test(v) || 'No se permiten carácteres especiales',
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
    ],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    regions: [],
    communes: [],
    newRut: "",
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      // (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      // (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ]
  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.getRegions();
    this.getMonths();
    this.getYears();

    const userDataAux = localStorage.getItem('userData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const homeDataAux = localStorage.getItem('homeData');

    const query = this.$route.query;

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';
      this.homeData = abandoned.homeData;

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const homeDataLocal = JSON.stringify(this.homeData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('homeData', encryptedHomeDataLocal);

      this.getCommunes(true);

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
        this.changeRut(this.userData.rut);
      }

      if (this.userData.dates) {
        this.getDays(true);
      }

    } else {

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);

      this.getCommunes(true);

      if (this.userData.dates) {
        this.getDays(true);
      }

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
        this.changeRut(this.userData.rut);
      }

      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesHomeData = CryptoJS.AES.decrypt(homeDataAux, this.secretKey);
      const decryptedDataHome = bytesHomeData.toString(CryptoJS.enc.Utf8);
      this.homeData = JSON.parse(decryptedDataHome);
      // console.log("homeData: ", this.homeData);
    }

  },
  methods: {
    async changeBisiesto() {
      if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && !this.isBisiesto(this.userData.year.id) && this.days.length == 29) {
        this.userData.day = null;
        this.days = await dateService.getDays(
          this.userData.month.name, false
        );
      } else if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && this.isBisiesto(this.userData.year.id) && this.days.length == 28) {
        this.days = await dateService.getDays(
          this.userData.month.name, true
        );
      } else {
        this.days = await dateService.getDays(
          this.userData.month.name, null
        );
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    async setDataAddress(input) {
      if (input) {
        this.userData.address = this.homeData.addressHome;
        this.userData.addressNumber = this.homeData.addressNumberHome;
        this.userData.region = this.homeData.regionHome;
        this.userData.addressCondo = this.homeData.nCondominio;

        if (this.userData.tipoPropiedad === 'casa' && this.homeData.condominio != 'No') {
          this.userData.addressCondo = this.homeData.nCondominio;
        } else {
          this.userData.addressCondo = null;
        }

        if (this.userData.tipoPropiedad === 'departamento') {
          this.userData.addressDepto = '' + this.homeData.nPisoHome + ' Piso ' + this.homeData.nDptoHome;
        } else {
          this.userData.addressDepto = null;
        }

        this.getCommunes(true);
        this.userData.commune = this.homeData.communeHome;
      } else {
        this.userData.address = null;
        this.userData.addressNumber = null;
        this.userData.region = null;
        this.userData.addressCondo = null;
        this.userData.addressDepto = null;
        this.userData.commune = null;
      }
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes(input) {
      if (this.userData.region) {
        this.loadingCommunes = true;
        if (!input) {
          this.userData.commune = null;
        }
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    async validate() {
      if (this.$refs.form.validate()) {

        const fechaActual = new moment(new Date());
        var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
        var dateNacimiento = moment(new Date(newDate));
        var timesDiff = fechaActual.diff(dateNacimiento, 'years');

        if (timesDiff < 18) {
          swal({
            title: "Atención!",
            text: "Para contratar este seguro, usted debe ser mayor de edad!",
            icon: "info",
            button: "De acuerdo",
          });
        } else {

          var setVar = false;
          this.userData.dates = newDate;

          this.change();

          const userDataLocal = JSON.stringify(this.userData);
          const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
          localStorage.setItem('userData', encryptedUserDataLocal);

          if (this.activeCampaingUse == 'TRUE') {

            const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

            if (activeCampaingRes.data.data.meta.total != '0') {
              this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
              setVar = true;
            } else {
              this.servicesData.contactID = null;
            }

            const shieldRes = await ShieldService.updateContactActiveCampain(this.userData, this.planData, this.servicesData, 'datos contratante');

            this.servicesData.contactID = setVar ? shieldRes.data.data.contact.id : shieldRes.data.data.contacts[0].id;

            const servicesDataLocal = JSON.stringify(this.servicesData);
            const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
            localStorage.setItem('servicesData', encryptedServicesDataLocal);

          }

          this.$router.push({ path: '/resumen' })

        }

      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays(input) {
      if (this.userData.month) {
        this.loadingDays = true;
        if (!input) {
          this.userData.day = null;
        }
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async change() {

      if (this.userData.year && this.userData.month && this.userData.day) {
        const fechaActual = new Date();
        var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
        var diff = fechaActual.getFullYear() - this.userData.year.id
        if (diff > 18) {
          this.userData.dates = newDate;
        }
      }

      try {
        await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Datos personales');
      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #2D387C !important;
}

.v-textField {
  box-sizing: border-box;
  height: 48px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.radioButton {
  margin-left: 40%;
}

@media only screen and (max-width: 800px) {
  .radioButton {
    margin-left: 30%;
  }
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.v-input.v-textField.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed.v-select div.v-input__control div.v-text-field__details div.v-messages.theme--light div.v-messages__wrapper div.v-messages__message {
  color: red;
}

.number-address {
  display: flex;
  //  gap: 10px;
}

.date-selector {
  max-width: 176px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.date-selector-movil {
  max-width: 85px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}
</style>