<template>
  <v-container style="margin:auto">

    <NavBar :step="2" />

    <Titulo></Titulo>

    <br v-if="screen < 800">


    <br>
    <br>

    <v-col>
      <v-stepper v-model="e1" elevation="0">
        <v-stepper-header v-if="screen > 800" style="max-width: 38%; margin: auto;">
          <div class="circle-step">
            <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
              step="1"> Inicio
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3">
              Beneficios
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-header v-if="screen < 800">
          <div class="circle-step">
            <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
              step="1"> Inicio
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3">
              Beneficios
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
            </v-stepper-step>
          </div>
        </v-stepper-header>
        <br v-if="screen < 800">



        <v-stepper-items>
          <v-stepper-content step="3">

            <div>

              <v-form ref="form" v-model="valid" max-witdh="300px">

                <!-- <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em;
                      color: #2D387C;">Asegura tu propiedad por</h3> -->
                      
                <br v-if="screen > 800">
                <br v-if="screen > 800">

                <div v-if="screen > 800" class="plan-container">
                  <BenefEscritorio :userData="userData" :homeData="homeData" :servicesData="servicesData" />
                </div>

                <div v-if="screen <= 800" class="plan-container">
                  <BenefMovil :userData="userData" :homeData="homeData" :servicesData="servicesData" />
                </div>

                <!-- <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Selecciona uno de los planes que tenemos para tu mascota</h4>
                  <br>
  
                  <v-row>
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                  <v-text-field :class="labelDescuento" v-model="userData.couponId"
                    label="Ingresa aquí tu código de descuento" maxlength="51" :rules="couponRules" solo></v-text-field>
  
                    <v-btn filled solo
                      style="width:70px; height: 50px; border-radius: 0px 10px 10px 0px; font-family: Helvetica; font-style: normal; font-weight: 700;
                        font-size: 14px; line-height: 143.34%; background-color:#FFB600; color:white; text-transform: capitalize ;"
                      @click="SendCouponID()">Aplicar</v-btn>
  
                  </v-row> -->

              </v-form>

            </div>

            <br>
            <br>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </v-col>
  </v-container>
</template>

<script>
import BenefEscritorio from "@/components/BenefEscritorio.vue";
import BenefMovil from "@/components/BenefMovil.vue";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';

export default {
  name: "PlanForm",
  components: { BenefEscritorio, BenefMovil, Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    valid: false,

    stepperColor: '#516EB3',
    e1: 3,
    screen: window.screen.width,

    userData: {
      flagAddress: false,
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      addressNumber: null,
      addressDepto: null,
      addressCondo: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      tipoPropiedad: null,
      calidadPersona: null,
      recomendacion: null,
      day: null,
      month: null,
      year: null,
    },
    homeData: {
      direccion: null,
      addressHome: null,
      addressNumberHome: null,
      communeHome: null,
      communeHomeCode: null,
      regionHome: null,
      regionHomeCode: null,
      condominio: null,
      nCondominio: null,
      requisitos: null,
      material: null,
      country: null,
      center: { lat: -33.4489, lng: -70.6693, },
      nDptoHome: null,
      nPisoHome: null,
      estructuraUF: null,
      contenidoUF: null,
      priceCLP: null,
      priceUF: null,
      couponId: null,
      infoBenef: {
        incendioEstructura: false,
        incendioContenido: false,
        sismoEstructura: false,
        sismoContenido: false,
        roboContenido: false,
        gastosMedicos: false,
        asistenciaMedica: false,
        seguroMascota: false,
      },
      valores: {
        valorIncendioEstructura: null,
        valorIncendioContenido: null,
        valorSismoEstructura: null,
        valorSismoContenido: null,
        valorRoboContenido: null,
        valorGastosMedicos: 1000,
        valorAsistenciaMedica: 1000,
        valorSeguroMascota: 1000,
        valorAsistenciaHogar: 1000,

        valorIncendioEstructuraUF: 0,
        valorIncendioContenidoUF: 0,
        valorSismoEstructuraUF: 0,
        valorSismoContenidoUF: 0,
        valorRoboContenidoUF: 0,
        valorGastosMedicosUF: 0,
        valorAsistenciaMedicaUF: 0,
        valorSeguroMascotaUF: 0,
        valorAsistenciaHogarUF: 0,
      },
      valoresUf: {
        estructuraInput: 1000,
        estructuraSlider: 1000,

        contenidoInput: 0,
        contenidoSlider: 0,
      },
      discount: {
        active: false,
        value: 1,
      }
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },

    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
  }),
  async mounted() {

    const query = this.$route.query;

    const userDataAux = localStorage.getItem('userData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const homeDataAux = localStorage.getItem('homeData');

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.homeData = abandoned.homeData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const homeDataLocal = JSON.stringify(this.homeData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('homeData', encryptedHomeDataLocal);

    } else if (userDataAux) {

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesHomeData = CryptoJS.AES.decrypt(homeDataAux, this.secretKey);
      const decryptedDataHome = bytesHomeData.toString(CryptoJS.enc.Utf8);
      this.homeData = JSON.parse(decryptedDataHome);
      // console.log("homeData: ", this.homeData);

    }

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 73%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

#app>div>main>div>div>div.col>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__header {
  max-width: 80%;
}
</style>