<template>
  <v-container style="margin: auto;">


    <!-- <v-stepper-items>
            <v-stepper-content step="1">
                <div class="step-data">
                    
                </div>
            </v-stepper-content>
        </v-stepper-items> -->

    <v-form>

      <h3 class="text-center text-tittle-coberturas" style="max-width: 300px; margin: auto;">
        ¿Quieres agregar coberturas o asistencias adicionales a tu seguro?
      </h3>

      <p></p>
      <p class="textoSub">Puedes usar la barra para elegir el monto o el botón ayuda para guiarte.</p>

      <v-card class="mx-auto box-conteiner1 " max-width="344">
        <!-- <img :src="elipse" alt="elipse" class="elipse"> -->

        <v-row>

          <div class="purple-image">
            <img :src="asistenciaHogar" alt="Image 1" class="imagen">
          </div>

          <v-card-title class="text-subTittle1">
            Asistencia Hogar Reparaciones
          </v-card-title>

          <v-card-actions>

            <v-spacer></v-spacer>

            <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
              class="imagen-arrow" @click="show = !show">
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>

          </v-card-actions>

        </v-row>

        <v-expand-transition>

          <div v-show="show">


            <v-card-text class="text-content1">
              Con este servicio contarás con ayuda para reparar los diferentes problemas de urgencia
              que pueden ocurrir en tu hogar, ya sea de Gasfitería, Electricidad Cristalería y Plomería.
              También incluye ayuda para la instalación de televisores, lavadoras, cortinas y tambien artefactos de gas.
              <br><br>
              <p class="text-blue"> <a class="text-blue" :href="documentHogarReparaciones" target="_blank">Conoce más
                  aquí.</a></p>
            </v-card-text>

          </div>

        </v-expand-transition>

        <v-row>
          <img :src="ticket" alt="ticket" style="width: 8px; height: 6px; margin-top: 4%; margin-left: 20%;">
          <div class="box-incluido1">
            Incluido
          </div>
        </v-row>

        <!-- <div class="box-incluido1">
                    Incluido
                  </div> -->

      </v-card>

      <v-card class="mx-auto box-conteiner1 " max-width="344">

        <!-- <img :src="elipse" alt="elipse" class="elipse"> -->

        <v-row>

          <div class="purple-image">
            <img :src="gastosMedicos" alt="Image 2" class="imagen">
          </div>

          <v-card-title class="text-subTittle1">
            Protección Gastos Médicos <br> Asesora del hogar
          </v-card-title>

          <v-card-actions>

            <v-spacer></v-spacer>

            <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
              class="imagen-arrow" @click="show1 = !show1">
              <v-icon>{{ show1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>

          </v-card-actions>

        </v-row>

        <v-expand-transition>

          <div v-show="show1">

            <p></p>

            <v-card-text class="text-content1">
              Protege financieramente la salud de quienes trabajan en tu casa como Asesoras del
              hogar, Enfermeras, cuidadores, entre otros, que tengan contrato de trabajo con
              domicilio en tu hogar asegurado. Con esta cobertura, en caso de que ellos sufran
              un accidente y tengan gastos de asistencia médica, farmacéutica y hospitalaria,
              reembolsaremos los gastos no cubiertos por su previsión de salud y seguros de salud, por hasta 100UF.
              <br><br>
              <p class="text-blue"> <a class="text-blue" :href="documentGastosMedicos" target="_blank">Conoce más
                  aquí.</a></p>
            </v-card-text>

            <div class="valor-agregado">
              + {{ formatPrice(homeData.valores.valorGastosMedicos) }}
            </div>

          </div>

        </v-expand-transition>

        <v-row>

          <v-checkbox class="checkbox" v-model="homeData.infoBenef.gastosMedicos"
            @change="calculatePrice()"></v-checkbox>

          <div v-if="homeData.infoBenef.gastosMedicos == false" class="box-incluir">
            Agregar
          </div>

          <div v-if="homeData.infoBenef.gastosMedicos == true" class="box-incluir">
            Quitar
          </div>

        </v-row>

      </v-card>


      <v-card class="mx-auto box-conteiner1 " max-width="344">

        <!-- <img :src="elipse" alt="elipse" class="elipse"> -->

        <v-row>

          <div class="purple-image">
            <img :src="asistenciaMedica" alt="Image 3" class="imagen">
          </div>

          <v-card-title class="text-subTittle1">
            Asistencia Médica Online <br> Full Familia
          </v-card-title>

          <v-card-actions>

            <v-spacer></v-spacer>

            <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
              class="imagen-arrow" @click="show2 = !show2">
              <v-icon>{{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>

          </v-card-actions>

        </v-row>

        <v-expand-transition>

          <div v-show="show2">

            <p></p>

            <v-card-text class="text-content1">
              Sin salir de tu casa podrás tener la asistencia y guía de una red médicos acreditados para ayudarte con
              a tí y tu familia con tus problemas de salud, excelente opción antes de acudir a un centro médico.
              Nuestros médicos podrán ayudarte con información, recomendación, indicación, orientación diagnóstica,
              prescripción o tratamiento según sea el caso, todo desde tu PC o celular en la comodidad de tu hogar
              asegurado.
              Incluye un seguro de muerte accidental por 50UF en caso que algo te ocurra.
              <br><br>
              <p class="text-blue"> <a class="text-blue" :href="documentTelemedicina" target="_blank">Conoce más
                  aquí.</a></p>
            </v-card-text>

            <div class="valor-agregado">
              + {{ formatPrice(homeData.valores.valorAsistenciaMedica) }}
            </div>

          </div>

        </v-expand-transition>

        <v-row>

          <v-checkbox class="checkbox" v-model="homeData.infoBenef.asistenciaMedica"
            @change="calculatePrice()"></v-checkbox>

          <div v-if="homeData.infoBenef.asistenciaMedica == false" class="box-incluir">
            Agregar
          </div>

          <div v-if="homeData.infoBenef.asistenciaMedica == true" class="box-incluir">
            Quitar
          </div>

        </v-row>

      </v-card>

      <!-- <v-card class="mx-auto box-conteiner1 " max-width="344">

        <v-row>

          <div class="purple-image">
            <img :src="seguroMascota" alt="Image 4" class="imagen">
          </div>

          <v-card-title class="text-subTittle1">
            Seguro Mascota protegida
          </v-card-title>

          <v-card-actions>

            <v-spacer></v-spacer>

            <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
              class="imagen-arrow" @click="show3 = !show3">
              <v-icon>{{ show3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>

          </v-card-actions>

        </v-row>

        <v-expand-transition>

          <div v-show="show3">

            <v-card-text class="text-content1">
              Protege la salud y bienestar de tu mascotas con las asistencias para Perros y Gatos que
              tenemos disponibles para tu familia. Desde consultas médicas a domicilio por urgencia,
              baño y peluquería. Incluye un seguro de Responsabilidad civil si tu mascota daña a una
              persona u otra mascota. Orientación Médica y legal en caso que algo ocurra con tu mascota, etc.
            <br><br>
   <p class="text-blue"> <a class="text-blue" :href="documentMacotas" target="_blank">Conoce más aquí.</a></p>
   </v-card-text> 

            <div class="valor-agregado">
              + {{ formatPrice(homeData.valores.valorSeguroMascota) }}
            </div>

          </div>

        </v-expand-transition>

        <v-row>

          <v-checkbox class="checkbox" v-model="homeData.infoBenef.seguroMascota" @change="calculatePrice()"></v-checkbox>

          <div v-if="homeData.infoBenef.seguroMascota == false" class="box-incluir">
            Agregar
          </div>

          <div v-if="homeData.infoBenef.seguroMascota == true" class="box-incluir">
            Quitar
          </div>

        </v-row>

      </v-card> -->

      <!-- <v-row style="margin-right: 10%; margin-left: 5%; margin-top: 10%; margin-bottom: 10%;">

        &nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;

        <v-text-field class="labelDescuento" label="Ingresa aquí tu código de descuento" maxlength="51"
          :rules="couponRules" solo>
        </v-text-field>

        <v-btn filled solo class="button-aplicar" style=" height: 50px; background-color:#2D387C;"
          @click="SendCouponID()">
          Aplicar
        </v-btn>

      </v-row> -->

    </v-form>

    <br>
    <br>

    <div style="  margin-top: 10%" :class="{ 'sticky-footer': isSticky }">

      <BotLine />
      <div class="tu-plan ">
        Tu plan mensual:
        <v-row style="align-items: baseline;">
          <p style="font-size: 20px;">{{ formatPrice(homeData.priceCLP) }}</p>
          <p style="margin-top: 2%;margin-left: 8px; ">(UF {{ Number(homeData.priceUF).toFixed(2) }})</p>
        </v-row>
      </div>
    </div>
    <div class="button-container">
      <v-btn class="button-continuar"
        style="background-color: #2D387C; height: 43px; margin-left: 210px; margin-top: 10px;" @click="validate()">
        Continuar
      </v-btn>
    </div>

    <!-- <div style="  margin-top: 20%" :class="{ 'sticky-footer': isSticky }">
      <BotLine />
      <div class="tu-plan ">
        Tu plan mensual:
        <v-row style="align-items: baseline;">
          <p style="font-size: 20px;">{{ formatPrice(homeData.priceCLP) }}</p>
          <p style="margin-top: 2%;margin-left: 8px; ">(UF {{ Number(homeData.priceUF).toFixed(2) }})</p>
        </v-row>
      </div>
    </div>
    <div class="button-container" style="margin-top: -23%;">
      <v-btn class="button-continuar"
        style="background-color: #2D387C; height: 43px; margin-left: 210px; margin-top: 10px;" @click="validate()">
        Continuar
      </v-btn>
    </div> -->

  </v-container>
</template>


<script>


import asistenciaHogar from '@/assets/images/asistenciaHogar.png';
import gastosMedicos from '@/assets/images/gastosMedicos.png';
import asistenciaMedica from '@/assets/images/asistenciaMedica.png';
import seguroMascota from '@/assets/images/seguroMascota.png';
import arrow from '@/assets/images/back-arrow.png';
import ticket from '@/assets/images/ticket.png';

import BotLine from "@/components/BotLine.vue";

import ShieldService from "../services/shield.service";
import Dynamic from "../services/dynamic.service";

import CryptoJS from 'crypto-js';

export default {
  name: "Beneficios",
  props: ['userData', 'homeData', 'servicesData'],
  components: { BotLine },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    isSticky: true,

    documentHogarReparaciones: '/documents/AsistenciaHogar.pdf',
    documentGastosMedicos: '/documents/GastosMedicos.pdf',
    documentTelemedicina: '/documents/AsistenciaTelemedicina.pdf',
    documentMacotas: '/documents/AsistenciaMascotas.pdf',

    show: false,
    show1: false,
    show2: false,
    show3: false,
    show4: false,

    asistenciaHogar,
    gastosMedicos,
    asistenciaMedica,
    seguroMascota,

    arrow,
    ticket,

    e1: 3,

    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',

    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
  }),
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    this.calculatePrice();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async validate() {

      const homeDataLocal = JSON.stringify(this.homeData);
      const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();
      localStorage.setItem('homeData', encryptedHomeDataLocal);

      try {

        await ShieldService.newCreateStep(null, null, this.homeData, this.servicesData, 'Beneficios');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.userData.email && this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        localStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      if (this.homeData.infoBenef.seguroMascota == true) {
        this.$router.push({ path: "/mascotas" });
      } else {
        if (this.userData.tipoPropiedad == "casa") {
          this.$router.push({ path: "/location" });
        } else if (this.userData.tipoPropiedad == "departamento") {
          this.$router.push({ path: "/location2" });
        }
      }

    },
    async change() {

    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async calculatePrice() {

      const ufShield = await ShieldService.getUf()
      const uf = ufShield.data

      // There we have to change between differeents types of coverages
      let incendioEstructura = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'estructura' ? 'building_fire' : null
      let incendioContenido = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'contenido' ? 'content_fire' : null
      let sismoEstructura = this.homeData.infoBenef.sismoEstructura == true ? 'building_earthquake' : null
      let sismoContenido = this.homeData.infoBenef.sismoContenido == true ? 'content_earthquake' : null
      let roboContenido = this.homeData.infoBenef.roboContenido == true ? 'content_theft' : null

      const userCoverages = [incendioEstructura, incendioContenido, sismoEstructura, sismoContenido, roboContenido]
      const userInputCoverages = userCoverages.filter(cov => cov != null)

      let gastosMedicos = this.homeData.infoBenef.gastosMedicos == true ? 'medical_refund' : null
      let asistenciaMedica = this.homeData.infoBenef.asistenciaMedica == true ? 'telemedicine_assistance' : null
      let seguroMascota = this.homeData.infoBenef.seguroMascota == true ? 'pet_assistance' : null
      const userAggregates = [gastosMedicos, asistenciaMedica, seguroMascota]
      const userInputAggregates = userAggregates.filter(agg => agg != null)

      const userInput = {
        usrCoverages: userInputCoverages,
        usrAggregates: userInputAggregates,
        usrCapitals: {
          "building": this.homeData.valoresUf.estructuraSlider,
          "content": this.homeData.valoresUf.contenidoSlider,
          "content_theft": this.homeData.valoresUf.contenidoSlider * 0.6 // change 60%
        }
      }

      const results = Dynamic.calculate(userInput);
      const options = Dynamic.calculateAllOptions(userInput);

      if (this.homeData.discount.active) {
        const homeAssistance = (options.aggregates.find(a => a.aggregate === 'home_assistance')?.aggregatePrice ?? 0) * this.homeData.discount.value
        const telemedeicineAssistance = (options.aggregates.find(a => a.aggregate === 'telemedicine_assistance')?.aggregatePrice ?? 0) * this.homeData.discount.value
        const petAssistance = (options.aggregates.find(a => a.aggregate === 'pet_assistance')?.aggregatePrice ?? 0) * this.homeData.discount.value
        const medicalRefunds = (options.aggregates.find(a => a.aggregate === 'medical_refund')?.aggregatePrice ?? 0) * this.homeData.discount.value

        this.homeData.valores.valorAsistenciaHogar = ((homeAssistance * uf) / 12)
        this.homeData.valores.valorAsistenciaMedica = ((telemedeicineAssistance * uf) / 12)
        this.homeData.valores.valorSeguroMascota = ((petAssistance * uf) / 12)
        this.homeData.valores.valorGastosMedicos = ((medicalRefunds * uf) / 12)

        // this.homeData.valores.valorAsistenciaHogar = ((homeAssistance * uf) / 12) * this.homeData.discount.value
        // this.homeData.valores.valorAsistenciaMedica = ((telemedeicineAssistance * uf) / 12) * this.homeData.discount.value
        // this.homeData.valores.valorSeguroMascota = ((petAssistance * uf) / 12) * this.homeData.discount.value
        // this.homeData.valores.valorGastosMedicos = ((medicalRefunds * uf) / 12) * this.homeData.discount.value

        this.homeData.valores.valorGastosMedicosUF = (medicalRefunds / 12)  // Valores Uf 
        this.homeData.valores.valorAsistenciaMedicaUF = (telemedeicineAssistance / 12)  // Valores Uf 
        this.homeData.valores.valorSeguroMascotaUF = (petAssistance / 12)  // Valores Uf 
        this.homeData.valores.valorAsistenciaHogarUF = (homeAssistance / 12)  // Valores Uf 

        // this.homeData.valores.valorGastosMedicosUF = (medicalRefunds / 12) * this.homeData.discount.value // Valores Uf 
        // this.homeData.valores.valorAsistenciaMedicaUF = (telemedeicineAssistance / 12) * this.homeData.discount.value // Valores Uf 
        // this.homeData.valores.valorSeguroMascotaUF = (petAssistance / 12) * this.homeData.discount.value // Valores Uf 
        // this.homeData.valores.valorAsistenciaHogarUF = (homeAssistance / 12) * this.homeData.discount.value // Valores Uf 

        this.homeData.priceUF = (Number(results.total / 12)) * this.homeData.discount.value

      } else {
        const homeAssistance = options.aggregates.find(a => a.aggregate === 'home_assistance')?.aggregatePrice ?? 0
        const telemedeicineAssistance = options.aggregates.find(a => a.aggregate === 'telemedicine_assistance')?.aggregatePrice ?? 0
        const petAssistance = options.aggregates.find(a => a.aggregate === 'pet_assistance')?.aggregatePrice ?? 0
        const medicalRefunds = options.aggregates.find(a => a.aggregate === 'medical_refund')?.aggregatePrice ?? 0

        this.homeData.valores.valorAsistenciaHogar = (homeAssistance * uf) / 12
        this.homeData.valores.valorAsistenciaMedica = (telemedeicineAssistance * uf) / 12
        this.homeData.valores.valorSeguroMascota = (petAssistance * uf) / 12
        this.homeData.valores.valorGastosMedicos = (medicalRefunds * uf) / 12

        this.homeData.valores.valorGastosMedicosUF = medicalRefunds / 12 // Valores Uf 
        this.homeData.valores.valorAsistenciaMedicaUF = telemedeicineAssistance / 12 // Valores Uf 
        this.homeData.valores.valorSeguroMascotaUF = petAssistance / 12 // Valores Uf 
        this.homeData.valores.valorAsistenciaHogarUF = homeAssistance / 12 // Valores Uf 

        this.homeData.priceUF = Number(results.total / 12)
      }

      this.homeData.priceCLP = (this.homeData.priceUF * uf);
    },
    handleScroll() {
      // const footer = this.$el;
      // const footerOffset = footer.offsetTop;
      // const scrollTop = window.scrollY || window.pageYOffset;

      // if (scrollTop > footerOffset) {
      //   this.isSticky = true;
      // } else {
      //   this.isSticky = false;
      // }
    },

  }
}

</script>

<style lang="scss" scoped>
.text-tittle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.text-tittle-coberturas {
  color: var(--AD-Primario-1, #2D387C);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.textoSub {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
  max-width: 300px;
  margin: auto;
}

.div-uf {
  margin: 10px;
  padding: 9px 33px;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #7b7979;
  background: #FFF;
  margin-right: 4%;
  margin-left: 40%;
  height: 3%;
  display: flex;
  width: 232px;
  height: 48px;
}

.slider {
  display: flex;
  width: 539px;
  height: 7px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  padding-right: 25%;
  padding-left: 25%;
}

.box-conteiner1 {
  margin-top: 5%;
  padding-right: 12%;
  background-color: rgba(218, 220, 251, 0.46);
  /* Color de fondo con opacidad */
  box-shadow: none !important;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9F9FF;
  /* Color de fondo */
  opacity: 0.56;
  /* Opacidad del fondo */
  z-index: -1;
  /* Coloca el fondo detrás del contenido */
}

.imagen-arrow {
  position: absolute;
  right: 0;
  opacity: none;
}

// .elipse{
//   height: 100%;
//   margin-left: 100%;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   top: 0;
// }

// .elipse{
//   height: 10%;
//   margin-left: 60%;
//   position: absolute;
//   top: 0;
// }

.purple-image {
  margin-right: 2%;
  width: 10%;
  padding-left: 1.5%;
  padding-top: 1.5%;
  margin-left: 2%;
  margin-top: 1%;
  background: var(--AD-Secundario-1, #4F74E3);
  border-radius: 6px;
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  position: relative;
}

.imagen {
  width: 26.922px;
  height: 19.772px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // position: absolute;
}



.text-subTittle1 {
  color: var(--AD-Primario-1, #070783);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 100% */
  margin-left: 4%;
}

.text-content1 {
  color: var(--AD-Texto-1, #333);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  margin-left: 15%;

}

.box-incluido1 {
  color: var(--AD-Secundario-1, #4F74E3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  margin-left: 5%;
  margin-top: 2%;
  padding-bottom: 5%;
}

.valor-agregado {
  margin-left: 18%;
  margin-top: 5%;
  color: var(--AD-Primario-1, #2D387C);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.checkbox {
  margin-left: 18%;
  margin-bottom: -10%;
  margin-right: -15%;
}


.box-incluir {
  color: var(--AD-Secundario-1, #4F74E3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  margin-left: 16%;
  margin-top: 2%;
  padding-bottom: 5%;
}

// @media only screen and (max-width: 1700px) {
// .box-incluido{
//     background: var(--AD-Primario-2, #4F74E3); 
//     border-radius: 8px; 
//     padding-right: 4%; 
//     padding-left: 4%;  
//     padding-top: 6%; 
//     padding-bottom: 6%; 
//     color: #FFF; 
//     text-align: center; 
//     font-family: Arial, Helvetica, sans-serif; 
//     font-size: 16px; 
//     font-style: nohermal; 
//     font-weight: 600; 
//     line-height: 16px; /* 100% */

// }
// }



.button-aplicar {
  width: 70px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143.34%;
  color: white;
  text-transform: capitalize;
}

.labelDescuento {
  width: 30%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.tu-plan {
  background-color: white;
  padding-bottom: 5%;
  padding-left: 5%;
  color: var(--AD-Primario-1, #2D387C);
  font-size: 16px;
  font-weight: 700;
  margin-top: -40%;
  padding-top: 8%;
}

.button-continuar {
  color: white;
  display: flex;
  width: 169px;
  padding: 12px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.button-ayuda {
  color: var(--AD-Primario-1, #2D387C);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  display: flex;
  width: 72px;
  height: 22px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 33px;
  background: #E0E1FF;
}


@media only screen and (max-width: 800px) {
  .text-tittle {
    color: var(--AD-Primario-1, #2D387C);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
  }


  .text-tittle-coberturas {
    color: var(--AD-Primario-1, #2D387C);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  .div-uf {
    margin: 7%;
    margin-left: 30%;
    margin-right: 5%;

    border-radius: 6px;
    border: 1px solid #D6D6D6;
    background: #FFF;

    display: flex;
    width: 123px;
    height: 28px;
    padding: 9px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .number-uf {
    color: var(--AD-Texto-1, #333);
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 106.667% */
    margin-top: 30%;
  }

  .slider {
    display: flex;
    width: 539px;
    height: 7px;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    padding-right: 25%;
    padding-left: 25%;

    margin-top: -5%;
    margin-bottom: 5%;

  }

  .sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Estilos adicionales para el footer fijo en la parte inferior */
    /* ... */
  }

  .text-blue {
    font-family: Arial, Helvetica, sans-serif;
    color: #4F74E3;
    text-decoration: underline;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

}
</style>