const coverages = [
  {
    kind: "building_fire",
    tasaAnual: 0.29,
    capitalType: "building",
  },
  {
    kind: "content_fire",
    tasaAnual: 0.29,
    capitalType: "content",
  },
  {
    kind: "building_earthquake",
    tasaAnual: 1.15,
    capitalType: "building",
  },
  {
    kind: "content_earthquake",
    tasaAnual: 1.15,
    capitalType: "content",
  },
  {
    kind: "content_theft",
    tasaAnual: 1.42,
    capitalType: "content_theft",
  },
  {
    kind: "cristals",
    tasaAnual: 1.8,
    capitalType: "static",
    aggregations: ["home_assistance", "cyber_assistance"],
    value: 50,
  },
  {
    kind: "medical_expenses",
    tasaAnual: 1.28,
    aggregations: ["medical_refund"],
    capitalType: "static",
    value: 100,
  },
  {
    kind: "pet_responsability",
    tasaAnual: 9.80134117593207,
    capitalType: "static",
    aggregations: ["pet_assistance"],
    value: 20,
  },
  {
    kind: "accidental_death",
    tasaAnual: 0.3855,
    capitalType: "static",
    aggregations: ["telemedicine_assistance"],
    value: 50,
  },
];

const aggregates = [
  {
    kind: "home_assistance",
    tasaAnual: 0.42,
    mandatory: true,
    withTaxes: false,
  },
  {
    kind: "telemedicine_assistance",
    tasaAnual: 0.0564,
    mandatory: false,
    withTaxes: true,
  },
  {
    kind: "pet_assistance",
    tasaAnual: 0.39936,
    mandatory: false,
    withTaxes: false,
  },
  {
    kind: "cyber_assistance",
    tasaAnual: 0.0275815370159179,
    mandatory: false,
    withTaxes: false,
  },
  {
    kind: "medical_refund",
    tasaAnual: 0.0,
    mandatory: false,
    withTaxes: false,
  },
];

const coveragesMinPrices = [
  { kinds: ["building_fire", "content_fire"], minAnualPrice: 2 },
  { kinds: ["building_earthquake", "content_earthquake"], minAnualPrice: 0.5 },
  { kinds: ["content_theft"], minAnualPrice: 0.85 },
];

const loadings = 0.43;
const taxes = 1.19;

class DynamicPlan {
  constructor(coverages, aggregates, coveragesMinPrices, loadings, taxes) {
    this.taxes = taxes;
    this.loadings = loadings;
    this.coverages = coverages;
    this.aggregates = aggregates;
    this.coveragesMinPrices = coveragesMinPrices;

    if (!this.taxes)
      throw new Error("Taxes must be defined to create a dynamic plan");
    if (!this.loadings)
      throw new Error("loadings must be defined to create a dynamic plan");
    if (!this.coverages)
      throw new Error("Coverages must be defined to create a dynamic plan");
    if (!this.aggregates)
      throw new Error("Aggregates must be defined to create a dynamic plan");
    if (!this.coveragesMinPrices)
      throw new Error(
        "coveragesMinPrices must be defined to create a dynamic plan"
      );
  }

  getUniquesCoverageKinds() {
    const coveragesKinds = this.coverages.map((coverage) => coverage.kind);
    return [...new Set(coveragesKinds)];
  }

  getUniquesAggregatesKinds() {
    const aggregatesKinds = this.aggregates.map((aggregate) => aggregate.kind);
    return [...new Set(aggregatesKinds)];
  }

  calculate(input) {
    const { usrCoverages, usrAggregates, usrCapitals } = input;

    const aggregatesWithPrices = this.aggregates.map((aggregate) => {
      if (!usrAggregates.includes(aggregate.kind) && !aggregate.mandatory)
        return { kind: aggregate.kind, price: 0 };
      const { kind } = aggregate;
      const price = aggregate.withTaxes
        ? (aggregate.tasaAnual / (1 - this.loadings)) * this.taxes
        : aggregate.tasaAnual / (1 - this.loadings);
      return { kind, price };
    });

    const coveragesToCalculate = this.coverages.filter(
      (coverage) =>
        usrCoverages.includes(coverage.kind) ||
        coverage.capitalType === "static"
    );
    const capitalsToCalculate = Object.keys(usrCapitals);

    const coveragesWithPrices = coveragesToCalculate.map((coverage) => {
      const { kind, capitalType, value } = coverage;
      const capital = capitalsToCalculate.find((cap) => cap === capitalType);
      const capitalValue = capital ? usrCapitals[capital] : value;

      let price =
        (capitalValue * coverage.tasaAnual) / (1 - this.loadings) / 1000;
      // Now we add to the price the coverage.aggregations if exists
      // The aggregates are aggregatesWithPrices
      if (coverage.aggregations) {
        const selectedAggregation = this.aggregates.find((aggregate) =>
          coverage.aggregations.includes(aggregate.kind)
        );
        // If the coverage has aggregations and no aggregates are selected, we return 0
        if (
          !usrAggregates.some((aggregate) =>
            coverage.aggregations.includes(aggregate)
          ) &&
          !selectedAggregation.mandatory
        )
          return {
            kind,
            capitalType,
            capitalValue,
            tasaAnual: coverage.tasaAnual,
            price: 0,
          };

        const aggregationToAdd = aggregatesWithPrices.filter((aggregate) =>
          coverage.aggregations.includes(aggregate.kind)
        );
        const aggregationPrice = aggregationToAdd.reduce(
          (acc, aggregate) => acc + aggregate.price,
          0
        );
        price += aggregationPrice;
      }

      // Check if the price is less than the minimum price.
      // The minimun price is minPricesByInput

      return {
        kind,
        capitalType,
        capitalValue,
        tasaAnual: coverage.tasaAnual,
        price,
      };
    });

    const finalTaxedCoverages = [
      { kinds: ["building_fire", "content_fire"], value: 1.19 },
      {
        kinds: [
          "content_theft",
          "cristals",
          "medical_expenses",
          "pet_responsability",
        ],
        value: 1.19,
      },
    ];

    const coveragesToReplace = coveragesMinPrices.map((minCov) => {
      const currentCovGroup = coveragesWithPrices.filter(
        (c) => minCov.kinds.includes(c.kind) && c.price > 0
      );

      const currentCovGroupKinds = currentCovGroup.map((c) => c.kind);

      const currentCovGroupSum = currentCovGroup.reduce((acc, cov) => {
        return acc + cov.price;
      }, 0);
      const currentCovGroupPriceByElement =
        currentCovGroupSum / currentCovGroup.length;
      const hasToBeReplaced = currentCovGroupSum < minCov.minAnualPrice;
      return {
        kinds: currentCovGroupKinds,
        elementValue: hasToBeReplaced
          ? minCov.minAnualPrice / currentCovGroup.length
          : currentCovGroupPriceByElement,
        hasToBeReplaced,
      };
    });

    const totalCoveragesPrice = coveragesWithPrices.reduce((acc, coverage) => {
      const factor = finalTaxedCoverages.find((ftc) =>
        ftc.kinds.includes(coverage.kind)
      );

      const coveragePrice = coveragesToReplace.find((c) =>
        c.kinds.includes(coverage.kind)
      )?.hasToBeReplaced
        ? coveragesToReplace.find((c) => c.kinds.includes(coverage.kind))
            .elementValue
        : coverage.price;
      

      const factorValue = factor ? factor.value : 1;
      const value = acc + coveragePrice * factorValue
      return value;
    }, 0);

    return {
      coverages: coveragesWithPrices,
      aggregates: aggregatesWithPrices,
      total: totalCoveragesPrice,
    };
  }

  calculateAllOptions(input) {
    const { usrCoverages, usrAggregates } = input;
    const currentTotal = this.calculate(input).total;
    const coveragesToOffer = this.getUniquesCoverageKinds().filter(
      (coverage) => !usrCoverages.includes(coverage)
    );
    const aggregatesToOffer = this.getUniquesAggregatesKinds().filter(
      (aggregate) => !usrAggregates.includes(aggregate)
    );

    const coveragesOptions = coveragesToOffer.map((coverage) => {
      const newUsrCoverages = [...usrCoverages, coverage];
      const newInput = { ...input, usrCoverages: newUsrCoverages };
      const { total } = this.calculate(newInput);
      const coveragePrice = total - currentTotal;
      return { coverage, total, coveragePrice };
    });

    const aggregatesOptions = aggregatesToOffer.map((aggregate) => {
      const newUsrAggregates = [...usrAggregates, aggregate];
      const newInput = { ...input, usrAggregates: newUsrAggregates };
      const { total } = this.calculate(newInput);
      const aggregatePrice = total - currentTotal;
      return { aggregate, total, aggregatePrice };
    });

    return { coverages: coveragesOptions, aggregates: aggregatesOptions };
  }
}

module.exports = new DynamicPlan(
  coverages,
  aggregates,
  coveragesMinPrices,
  loadings,
  taxes
);
