<template>
    <!-- <div style="background: rgba(51, 51, 204, 1) 0% 0% no-repeat padding-box; opacity: 0.05;"> -->
    <div class=" text-center footer">
      
      <div style="background: var(--AD-Degradado-2, linear-gradient(90deg, #2D387C 68%, #4F74E3 83%)); width: 100%; height: 7px;
                margin-left: 2%;"></div>
      
     
    </div>
  </template>
  
  <script>
  //import LogoSB from "@/assets/images/logo2.jpg"; con background integrado
  import LogoSB from "@/assets/images/LogoFooter2.png";
  
  export default {
    name: "BotLine",
    mounted() {
    },
    data: () => ({
      LogoSB
    }),
  };
  </script>
  
  <style lang="scss" scoped>
  @import url("../styles/main.scss");
  
  .cobertura {
    font-family: Helvetica;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    font-weight: 550;
    color: #232323;
    line-height: 16px;
    opacity: 0.75;
  }
  
  @media only screen and (max-width: 1500px) {
    .cobertura {
      font-family: Helvetica;
      font-style: normal;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #232323;
      line-height: 13px;
      opacity: 0.75;
    }

    .footer {
    height: 140px;
    margin-right: 3%;
    //background: rgba(51, 51, 204, 0.062) 0% 0% no-repeat padding-box;
  }

    
  }
  
  @media only screen and (min-width: 1500px) {
  .footer {
    height: 140px;
    margin-left: -17%;
    //background: rgba(51, 51, 204, 0.062) 0% 0% no-repeat padding-box;
  }
} 
  </style>