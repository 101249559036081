import axios from "axios";

export default class locationService {

    

  static async getLocationByCity(city) {

    const locationRes = await axios.get(
      `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${city}&format=jsonv2&limit=1`
    );
    return locationRes.data
  }
}

