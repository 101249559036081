import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueSimpleAlert from "vue-simple-alert";
import * as VueGoogleMaps from 'vue2-google-maps';
import { googleMapsApiKey } from './google-maps-config';



Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: googleMapsApiKey,
    libraries: 'places', // Puedes añadir más bibliotecas si es necesario
  },
});


Vue.config.productionTip = false
Vue.config.silent = false // Deshabilitar para hacer QA.

Vue.use(VueSimpleAlert);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
