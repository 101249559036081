import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async newCreateStep(userData, petData, homeData, servicesData, step) {
    try {

      let url;
      const jsonData = { userData, petData, homeData, servicesData, step, sponsor: VUE_APP_SPONSOR };

      if (servicesData.type == 'create') {
        url = '/step/newCreate';
      } else if (servicesData.type == 'update') {
        url = '/step/newUpdate';
      }

      const carroResponse = await axios.post(
        `${VUE_APP_SHIELD_URL}${url}`,
        jsonData
      );

      return carroResponse;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error en el servicio Stepers",
      };
    }
  }

  static async newGetStepAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/newGetAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async createContactActiveCampain(userData, servicesData) {

    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/activeCampain/createContact`,
        {
          userData,
          servicesData,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el contacto",
      };
    }
  }

  static async updateContactActiveCampain(userData, planData, servicesData, index) {

    try {

      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/activeCampain/updateContact`,
        {
          userData,
          planData,
          servicesData,
          index,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async getContactActiveCampain(email) {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async createStep(values, step, version, id) {
    try {
      let carroRes;
      let data = {};
      let lenght = 0;

      //Para el primer step del formulario
      if (step == 1) {
        //Para el primera interacción
        if (version == 1) {
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            lenght: 1,
          };

          carroRes = await axios.post(
            `${VUE_APP_SHIELD_URL}/step/create`,
            params
          );
        }
        //Para las demás interacciones interacción
        else {
          values.forEach((element) => {
            if (element != null) {
              lenght = lenght + 1;
            }
          });
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            id: id,
            lenght: lenght,
          };

          carroRes = await axios.put(
            `${VUE_APP_SHIELD_URL}/step/update`,
            params
          );
        }
      }
      //Para el segundo step del formulario
      else if (step == 2) {
        const params = {
          sponsor: VUE_APP_SPONSOR,
          steptwo: values,
          id: id,
          lenght: 8,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }
      //Para el tercer step del formulario
      else if (step == 3) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          species: values[0],
          breed: values[1],
          petName: values[2],
          petAge: values[3],
          sex: values[4],
          hasMicrochip: values[5],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }

      else if (step == 4) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          name: values[0],
          surname: values[1],
          serialNumber: values[2],
          rut: values[3],
          region: values[4],
          commune: values[5],
          address: values[6],
          email: values[7],
          phoneNumber: values[8],
          species: values[9],
          breed: values[10],
          petName: values[11],
          petAge: values[12],
          sex: values[13],
          hasMicrochip: values[14],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }

      return carroRes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el Step",
      };
    }
  }

  static async createInsurance(person, pet, home, stepId, seguroMascota) {

    if (seguroMascota === false) {
      pet = null;
    }

    person.birthdate = person.dates;

    // const jsonData = { home, person, pet, planId: plan._id, stepId: stepId };
    const jsonData = { home, person, pet, stepId };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/hogar/createWithRunWayPayment`,
      jsonData
    );

    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;

    else throw shieldRes.data;
  }

  static async createInsuranceWithToku(person, pet, home, plan, stepId, seguroMascota) {

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    if (seguroMascota == false) {
      pet = null;
    }

    const jsonData = { home, person, pet, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/hogar/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async getUf() {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/hogar/getUf`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las uf",
      };
    }
  }


}


