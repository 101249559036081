<template>
  <v-container style="margin: auto;">

    <v-form ref="form" v-model="valid">

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'estructura'">

        <br>
        <br>

        <h3 class="text-center text-tittle">
          Ingresa el monto en UF por el que quieres asegurar tu hogar:
        </h3>

        <p></p>
        <p class="textoSub">Puedes usar la barra para elegir el monto o el botón ayuda para guiarte.</p>

        <v-row class="ufs" style="padding-top: 2%; align-items: center;">

          <div class="text-center div-uf">
            <p style="margin-top: 2%;">UF</p>
            <v-text-field style="text-align: center;" class="number-uf" v-model="homeData.valoresUf.estructuraInput"
              min="500" max="22000" @input="calculatePrice(true, 'no')" :rules="ufOneRules"></v-text-field>
          </div>
          <v-dialog v-model="dialog" width="600px">
            <v-card>

              <div style="background: var(--AD-Primario-2, #4F74E3);">

                <v-card-actions @click="dialog = false" style="color: white; margin-left: 97%; ">
                  X
                </v-card-actions>

                <v-row style="margin-left: 10%;">

                  <div class="white-image">
                    <img :src="imagenRecomendacion" alt="Image 1">
                  </div>

                  <v-card-tittle style="margin:auto; color: white;">
                    Te ayudamos a calcular los metros cuadrados
                  </v-card-tittle>

                </v-row>

              </div>

              <v-card-text class="form-container">

                <v-col>

                  <br>

                  <div class="labelTituloInfo">
                    ¿Cuantos metros cuadrados construidos tiene tu casa o departamento?
                  </div>

                  <br>
                  <br>

                  <v-container fluid>
                    <v-layout justify-center align-center>
                      <v-flex xs6>
                        <v-text-field v-model="inputCalculoEstructura" solo outlined :rules="metersRules"
                          placeholder="   m²" class="butonMetros">
                        </v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <br>
                        <v-btn @click="calculateEstructuraRecommend()" :disabled="!isInputValidEstructura"
                          style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white">
                          Calcular
                        </v-btn>
                      </v-flex>
                      <v-flex xs4>
                        <br>
                        <v-btn :disabled="!valorUtilizarEstructura || !isInputValidEstructura"
                          @click="setValueEstructura()"
                          style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white">
                          Utilizar
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <br>
                  <br>

                  <div style="border-radius: 4px; background: rgba(79, 116, 227, 0.08); text-align: center;"
                    class="bluebox-recommend">
                    <p></p>
                    Te recomendamos asegurar tu casa por:
                    <div v-if="valorUtilizarEstructura != null" class="labelValorUF">
                      {{ valorUtilizarEstructura }} UF
                    </div>

                  </div>

                </v-col>

              </v-card-text>

            </v-card>

          </v-dialog>

          <div>

            <v-btn class="button-ayuda" style="border-radius: 7px; background: #E0E1FF;" @click="dialog = true">
              Ayuda
            </v-btn>

          </div>

        </v-row>

        <div style="display: flex; margin: center;">

          <v-slider class="slider" color="#4F74E3" v-model="homeData.valoresUf.estructuraSlider" min="500" max="22000"
            @change="calculatePrice(false, 'no')"></v-slider>

        </div>

        <br>
        <br>

        <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex xs5>
              <label>UF 500</label>
            </v-flex>

            <v-flex xs1>
              <label>UF 22.000</label>
            </v-flex>
          </v-layout>
        </v-container>

        <br>
        <br>

        <h3 class="text-center text-tittle-coberturas">
          Para proteger tu hogar tenemos las siguientes coberturas:
        </h3>

      </div>


      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'estructura'"
        class="box-conteiner">

        <v-row>

          <div class="purple-image">
            <img :src="incendioEstructura" alt="Image 1">
          </div>

          <div style="padding-top: 2%;">

            <div class="text-subTittle">
              Incendio Estructura
            </div>

            <!-- <br> -->

            <div class="text-content">
              Cubre los daños materiales de tu hogar por la acción
              directa del incendio y los que sean <br>una consecuencia
              inmediata del incendio como los causados por el calor,
              el humo, el vapor <br> o lo que se usó para extinguir o
              contener el incendio; y las demoliciones que sean <br> necesarias o indicadas por la autoridad competente.
            </div>

          </div>

          <div class="box-incluir"
            style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

            <!-- <v-checkbox v-model="homeData.infoBenef.incendioEstructura" @change="calculatePrice()"></v-checkbox> -->

            <p>Incluido</p>

          </div>

        </v-row>

      </div>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'estructura'"
        class="box-conteiner">

        <v-row>

          <div class="purple-image">
            <img :src="sismoEstructura" alt="Image 1">
          </div>

          <div style="padding-top: 2%;">

            <div class="text-subTittle">
              Sismo Estructura
            </div>

            <!-- <br> -->

            <div class="text-content">
              Cubre los daños materiales que sufra tu hogar por la acción
              directa e inmediata de sismo. <br> Contempla la cobertura de retiro
              de escombros y de inhabitabilidad, que otorga un monto <br> mensual
              temporal en caso que tu hogar sea declarado inhabitable y
              acredites que haz <br>incurrido en un gasto de arrendamiento.
              No incluye cobertura de contenido de tu hogar.<br> Si lo
              necesitas la puedes agregar como adicional más adelante.
            </div>

          </div>

          <div class="box-incluir"
            style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

            <v-checkbox style="margin-bottom: -14.5px !important; padding-left: 5%"
              v-model="homeData.infoBenef.sismoEstructura" @change="calculatePrice('no', 'no')"></v-checkbox>
            <p v-if="!homeData.infoBenef.sismoEstructura" class="box-parrafo">Agregar</p>
            <p v-if="!homeData.infoBenef.sismoEstructura" class="box-parrafo">{{
              formatPrice(homeData.valores.valorSismoEstructura)
            }} </p>
            <p v-if="!homeData.infoBenef.sismoEstructura" class="box-parrafo">({{
              homeData.valores.valorSismoEstructuraUF.toFixed(4)
            }} UF)</p>
            <p v-if="homeData.infoBenef.sismoEstructura">Quitar<br><br> </p>

          </div>

        </v-row>

      </div>

      <br>
      <br>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'">

        <h3 class="text-center text-tittle">
          Ingresa el monto en UF por el que quieres asegurar tu hogar:
        </h3>

        <p></p>
        <p class="textoSub">Puedes usar la barra para elegir el monto o el botón ayuda para guiarte.</p>

        <v-row style="padding-top: 2%; align-items: center;">

          <div class="text-center div-uf2">
            <p style="margin-top: 2%;">UF</p>
            <v-text-field style="text-align: center;" class="number-uf2" v-model="homeData.valoresUf.contenidoInput"
              min="100" max="10000" @input="calculatePrice('no', true)" :rules="ufTwoRules"></v-text-field>
          </div>

          <v-dialog v-model="dialog2" width="600px">

            <v-card>

              <div style="background: var(--AD-Primario-2, #4F74E3);">

                <v-card-actions @click="dialog2 = false" style="color: white; margin-left: 97%;">
                  X
                </v-card-actions>

                <v-row style="margin-left: 10%;">

                  <div class="white-image">
                    <img :src="imagenRecomendacion" alt="Image 1">
                  </div>

                  <v-card-tittle style="margin: auto; color: white;">
                    Te ayudamos a calcular los metros cuadrados
                  </v-card-tittle>

                </v-row>

              </div>

              <v-card-text class="form-container">

                <v-col>

                  <br>

                  <div class="labelTituloInfo">
                    ¿Cuantos metros cuadrados construidos tiene tu casa o departamento?
                  </div>

                  <br>
                  <br>

                  <v-container fluid>
                    <v-layout justify-center align-center>
                      <v-flex xs6>
                        <v-text-field v-model="inputCalculoContenido" :rules="metersRules" placeholder="   m²" solo
                          outlined class="butonMetros">
                        </v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <br>
                        <v-btn @click="calculateContenidoRecommend()" :disabled="!isInputValidContenido"
                          style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white">
                          Calcular
                        </v-btn>
                      </v-flex>
                      <v-flex xs4>
                        <br>
                        <v-btn :disabled="!valorUtilizarContenido || !isInputValidContenido"
                          @click="setValueContenido()"
                          style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white">
                          Utilizar
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <br>
                  <br>

                  <div style="border-radius: 4px; background: rgba(79, 116, 227, 0.08); text-align: center;"
                    class="bluebox-recommend">
                    <p></p>
                    Te recomendamos asegurar tu casa por:
                    <div v-if="valorUtilizarContenido != null" class="labelValorUF">
                      {{ valorUtilizarContenido }} UF
                    </div>

                  </div>

                </v-col>

              </v-card-text>

            </v-card>

          </v-dialog>

          <!-- <div>

            <v-btn class="button-ayuda" style="border-radius: 7px; background: #E0E1FF;" @click="dialog2 = true">
              Ayuda
            </v-btn>

          </div> -->

        </v-row>

        <div style="display: flex; margin: center;">

          <v-slider class="slider" color="#4F74E3" v-model="homeData.valoresUf.contenidoSlider" min="100" max="10000"
            @change="calculatePrice('no', false)"></v-slider>

        </div>

        <br>
        <br>

        <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex xs5>
              <label>UF 100</label>
            </v-flex>

            <v-flex xs1>
              <label>UF 10.000</label>
            </v-flex>
          </v-layout>
        </v-container>

        <br>
        <br>

        <h3 class="text-center text-tittle-coberturas">
          Para proteger tu hogar tenemos las siguientes coberturas:
        </h3>

      </div>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner">

        <v-row>

          <div class="purple-image">
            <img class="imagen" :src="incendioContenido" alt="Image 1">
          </div>

          <div style=" padding-top: 2%;">

            <div class="text-subTittle">
              Incendio Contenido
            </div>

            <!-- <br> -->

            <div class="text-content">
              Cubre los daños que sufran los bienes se encuentren al interior de tu hogar y que no <br>
              forman parte de su estructura como muebles, televisores, electrodomésticos, etc., <br>por la
              acción directa del incendio y los que sean una consecuencia inmediata del incendio <br>
              como los causados por el calor, el humo, el vapor o lo que se usó para extinguir o <br> contener el
              incendio.
            </div>

          </div>

          <div class="box-incluir"
            style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

            <!-- <v-checkbox v-model="homeData.infoBenef.incendioContenido" @change="calculatePrice()"></v-checkbox> -->

            <p>Incluido</p>

          </div>

        </v-row>

      </div>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner">

        <v-row>

          <div class="purple-image">
            <img :src="sismoContenido" alt="Image 1">
          </div>

          <div style=" padding-top: 2%;">

            <div class="text-subTittle">
              Sismo Contenido
            </div>

            <!-- <br> -->

            <div class="text-content">
              Cubre los daños que sufran los bienes por la acción directa e inmediata de sismo que se <br>
              encuentren al interior de tu hogar y que no forman parte de su estructura como muebles, <br>
              televisores, electrodomésticos, etc.
            </div>

          </div>

          <div class="box-incluir-1"
            style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

            <v-checkbox style="margin-bottom: -14.5px !important; padding-left: 5%"
              v-model="homeData.infoBenef.sismoContenido" @change="calculatePrice('no', 'no')"></v-checkbox>
            <p v-if="!homeData.infoBenef.sismoContenido" class="box-parrafo" style="margin: 0;">Agregar</p>
            <p v-if="!homeData.infoBenef.sismoContenido" class="box-parrafo" style="margin: 0;">{{
              formatPrice(homeData.valores.valorSismoContenido) }}</p>
            <p v-if="!homeData.infoBenef.sismoContenido" class="box-parrafo" style="margin: 0;">({{
              homeData.valores.valorSismoContenidoUF.toFixed(4) }} UF)</p>
            <p v-if="homeData.infoBenef.sismoContenido" style="margin: 0;">Quitar<br><br> </p>

          </div>

        </v-row>

      </div>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner">
        <v-row>

          <div class="purple-image">
            <img :src="roboContenido" alt="Image 1">
          </div>

          <div style="padding-top: 2%;">

            <div class="text-subTittle">
              Robo Contenido (60% del contenido)
            </div>

            <!-- <br> -->

            <div class="text-content">
              Cubre por el monto asegurado los bienes que tienes al interior de tu hogar y que no forman <br>
              parte de su estructura como muebles, televisores, electrodomésticos, etc en caso de robo <br>
              en tu hogar.
            </div>

            <div class="text-content-uf">
              Monto asegurable para tu contenido: {{ Number(this.homeData.valoresUf.contenidoSlider * 0.60).toFixed(0)
              }} UF.
            </div>

          </div>

          <div class="box-incluir-1"
            style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

            <v-checkbox style="margin-bottom: -14.5px !important; padding-left: 5%"
              v-model="homeData.infoBenef.roboContenido" @change="calculatePrice('no', 'no')"></v-checkbox>
            <p v-if="!homeData.infoBenef.roboContenido" class="box-parrafo">Agregar</p>
            <p v-if="!homeData.infoBenef.roboContenido" class="box-parrafo">{{
              formatPrice(homeData.valores.valorRoboContenido)
            }}
            </p>
            <p v-if="!homeData.infoBenef.roboContenido" class="box-parrafo">({{
              homeData.valores.valorRoboContenidoUF.toFixed(4)
            }}
              UF)</p>
            <p v-if="homeData.infoBenef.roboContenido">Quitar<br><br> </p>
          </div>

        </v-row>
      </div>

      <br>
      <br>

      <h3 class="text-center text-tittle-coberturas">
        ¿Tienes un código de descuento promocional? Ingresalo aquí:
      </h3>

      <p></p>

      <!-- <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'" style="    padding-bottom: 6%;"> -->

      <!-- <h3 class="text-center text-tittle">
          Asegura robo contenido por
        </h3>

        <v-row class="ufs" style="padding-top: 2%; align-items: center;">

          <div class="text-center div-uf">
            <p style="margin-top: 2%;">UF</p>
            <v-text-field style="text-align: center;" class="number-uf" type="number" v-model="valorUf2" min="1" max="6000"
              @change="calculatePrice()" @input="updateValue3" :rules="ufThreeRules"></v-text-field>
          </div>

        </v-row> -->

      <!-- <div style="display: flex; margin: center;">

          <v-slider class="slider" color="#4F74E3" v-model="valorUf2" min="1" max="6000" @change="calculatePrice()"
            @input="updateValue3"></v-slider>

        </div> -->

      <br>
      <br>

      <!-- <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex xs5>
              <label>UF 1</label>
            </v-flex>

            <v-flex xs1>
              <label>UF 6.000</label>
            </v-flex>
          </v-layout>
        </v-container>

        <br>
        <br>

        <h3 class="text-center text-tittle-coberturas">
          Coberturas
        </h3> -->


      <!-- <div class="box-conteiner">

          <v-row>

            <div class="purple-image">
              <img :src="roboContenido" alt="Image 1">
            </div>

            <div style="padding-top: 2%;">

              <div class="text-subTittle">
                Robo Contenido (60% del contenido)
              </div>

              <br>

              <div class="text-content">
                Cubre por el monto asegurado los bienes que tienes al interior de tu hogar y que no forman <br>
                parte de su estructura como muebles, televisores, electrodomésticos, etc en caso de robo <br>
                en tu hogar.
              </div>

            </div>

            <div class="box-incluir-1"
              style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

              <v-checkbox v-model="homeData.infoBenef.roboContenido" @change="calculatePrice()"></v-checkbox>
              <p v-if="!homeData.infoBenef.roboContenido">Agregar<br><br> {{ formatPrice(homeData.valores.valorRoboContenido) }}</p>
              <p v-if="homeData.infoBenef.roboContenido == true">Quitar<br><br> </p>
            </div>

          </v-row>

        </div> -->





      <!-- </div> -->
    </v-form>

    <div style="margin-bottom: 7%;">
      <div style="margin-right: 30%; margin-left: 30%; margin-top: -3%; display:flex;">

        <v-text-field style="" class="labelDescuento" v-model="homeData.couponId" @blur="formatDiscount()"
          label="Ingresa aquí tu código de descuento" maxlength="51" :rules="couponRules" solo>
        </v-text-field>

        <v-btn filled solo class="button-aplicar" style=" height: 50px; background-color:#2D387C; "
          @click="SendCouponID()">Aplicar
        </v-btn>
      </div>
      <p v-if="homeData.discount.active" class="desc-parrafo">Descuento aplicado</p>
      <p v-if="!homeData.discount.active" class="desc-parrafo">&nbsp;&nbsp;</p>
    </div>

    <div style="background-color: white;" class="" :class="{ 'sticky-footer': isSticky }">

      <BotLine />
      <v-row class="precioPlan">
        <p
          style="margin-top: 1vw;  margin-left: 10%; color: var(--AD-Primario-1, #2D387C); font-weight: 700; margin-right: 0.5%;">
          Tu plan mensual: </p>
        <p
          style="margin-top: 0.8vw; font-size: 20px; color: var(--AD-Primario-1, #2D387C); font-weight: 700; margin-right: 0.5%; ">
          {{ formatPrice(homeData.priceCLP) }} </p>
        <p style="margin-top: 1vw; color: var(--AD-Primario-1, #2D387C); font-weight: 700; margin-right: 40%;">(UF {{
          Number(homeData.priceUF).toFixed(2) }})</p>
      </v-row>

    </div>

    <div class="button-container">

      <v-btn class="button-continuar" style="background-color: #2D387C; height: 43px; margin-left: 70%;"
        @click="validate()">
        Continuar
      </v-btn>

    </div>

  </v-container>
</template>


<script>

import Dynamic from "../services/dynamic.service.js";
import ShieldService from "../services/shield.service";

import incendioEstructura from '@/assets/images/incendioEstructura.png';
import sismoEstructura from '@/assets/images/sismoEstructura.png';
import incendioContenido from '@/assets/images/incendioContenido.png';
import sismoContenido from '@/assets/images/sismoContenido.png';
import roboContenido from '@/assets/images/roboContenido.png';
import imagenRecomendacion from '@/assets/images/recomendacion.png';

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';

import BotLine from "@/components/BotLine.vue";

export default {
  name: "Planes",
  props: ["userData", "homeData", "servicesData"],
  components: { BotLine },
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,

      isSticky: true,
      dialog: false,
      dialog2: false,

      // alertOne: false,
      // alertTwo: false,
      // alertThree: false,
      // alert60: false,

      inputCalculoEstructura: null,
      inputCalculoContenido: null,

      valorUtilizarEstructura: null,
      valorUtilizarContenido: null,

      incendioEstructura,
      sismoEstructura,
      incendioContenido,
      sismoContenido,
      roboContenido,

      imagenRecomendacion,

      e1: 2,
      valid: false,
      stepperColor: '#516EB3',

      activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',

      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      couponRules: [
        (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres",
        // (v) => !!v || 'El código de descuento es requerido.',
        (v) => (v && !/\s/.test(v)) || 'El código de descuento no puede contener espacios en blanco.'
      ],
      metersRules: [
        (v) => !!v || "El valor es requerido",
        (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      ],
      ufOneRules: [
        (v) => (v >= 500) || "El valor mínimo debe ser 500 UF",
        (v) => (v <= 22000) || "El valor máximo debe ser 22.000 UF"
      ],
      ufTwoRules: [
        (v) => (v >= 100) || "El valor mínimo debe ser 100 UF",
        (v) => (v <= 10000) || "El valor máximo debe ser 10.000 UF"
      ],
      ufThreeRules: [
        (v) => (v >= 0 && v <= 6000) || "El tope máximo es 6.000 UF"
      ],
    }
  },
  computed: {
    isInputValidEstructura() {
      return this.metersRules.every(rule => rule(this.inputCalculoEstructura) === true);
    },
    isInputValidContenido() {
      return this.metersRules.every(rule => rule(this.inputCalculoContenido) === true);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    this.calculatePrice('no', 'no');

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  // watch: {
  // valorUfAux(newVal) {
  //     if (newVal >= 1000 && newVal <= 22000) {
  //       this.valorUfAux2 = newVal;
  //     } else {
  //       swal({
  //         title: "Atención!",
  //         text: "El valor asegurado para la cobertura de robo no puede superar el 60% del valor asegurado para la coberturas de contenido.",
  //         icon: "info",
  //         button: "Ok",
  //       });
  //     }
  //   }
  // },
  methods: {
    async validate() {

      // if (this.alert60 == true){
      //   swal({
      //       title: "Atención!",
      //       text: "El valor asegurado para la cobertura de robo no puede superar el 60% del valor asegurado para la coberturas de contenido.",
      //       icon: "info",
      //       button: "Ok",
      //     }); 
      // } else {

      if (this.$refs.form.validate()) {

        if ((this.userData.recomendacion === 'estructura y contenido' && this.homeData.valoresUf.estructuraSlider >= this.homeData.valoresUf.contenidoSlider) || (this.userData.recomendacion !== 'estructura y contenido')) {
          if (this.userData.recomendacion === 'estructura y contenido' && this.homeData.valoresUf.contenidoSlider !== null || (this.userData.recomendacion !== 'estructura y contenido')) {

            const homeDataLocal = JSON.stringify(this.homeData);
            const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();
            localStorage.setItem('homeData', encryptedHomeDataLocal);

            try {

              await ShieldService.newCreateStep(this.userData, null, this.homeData, this.servicesData, 'Planes');

            } catch (error) {
              console.log("Cart Fail");
            }

            if (this.userData.email && this.activeCampaingUse == 'TRUE') {
              const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

              this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

              await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

              const servicesDataLocal = JSON.stringify(this.servicesData);
              const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
              localStorage.setItem('servicesData', encryptedServicesDataLocal);

            }

            this.$router.push({ path: "/beneficios" });

          } else {
            swal({
              title: "Atención!",
              text: "Debes ingresar un monto de UF a cubrir.",
              icon: "info",
              button: "Ok",
            });
          }
        } else if (this.homeData.valoresUf.contenidoSlider > this.homeData.valoresUf.estructuraSlider) {
          swal({
            title: "Atención!",
            text: "El monto asegurado no puede ser mayor que el de estructura, intenta con un monto menor.",
            icon: "info",
            button: "Ok",
          });
        }

      } else {
        if (this.homeData.valoresUf.estructuraSlider < 500 || this.homeData.valoresUf.estructuraInput < 500) {
          swal({
            title: "Atención!",
            text: "El monto mínimo a asegurar es 500 UF.",
            icon: "info",
            button: "Ok",
          });
        } else if (this.homeData.valoresUf.estructuraSlider > 22000 || this.homeData.valoresUf.estructuraInput > 22000) {
          swal({
            title: "Atención!",
            text: "El monto máximo a asegurar es 22.000 UF.",
            icon: "info",
            button: "Ok",
          });
        } else if (this.homeData.valoresUf.contenidoSlider < 100 || this.homeData.valoresUf.contenidoInput < 100) {
          swal({
            title: "Atención!",
            text: "El monto mínimo a asegurar es 100 UF.",
            icon: "info",
            button: "Ok",
          });
        } else if (this.homeData.valoresUf.contenidoSlider > 10000 || this.homeData.valoresUf.contenidoInput > 10000) {
          swal({
            title: "Atención!",
            text: "El monto máximo a asegurar es 10.000 UF.",
            icon: "info",
            button: "Ok",
          });
        }
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async calculateEstructuraRecommend() {
      this.valorUtilizarEstructura = this.inputCalculoEstructura * 30
    },
    async calculateContenidoRecommend() {
      this.valorUtilizarContenido = this.inputCalculoContenido * 7
    },
    // async updateValue() {
    //   if (this.valorUf < 1000) {
    //     this.valorUf = 1000;
    //     // this.alertOne = true;
    //   } else if (this.valorUf > 22000) {
    //     this.valorUf = 22000;
    //     // this.alertOne = true;
    //   } else {
    //     // this.alertOne = false;
    //   }
    // },
    // async updateValue2() {
    //   if (this.homeData.valoresUf.contenidoSlider > 10000) {
    //     this.homeData.valoresUf.contenidoSlider = 10000;
    //     // this.alertTwo = true;
    //   } else {
    //     // this.alertTwo = false;
    //   }
    // },
    // async updateValue3() {
    //   if (this.homeData.valoresUf.contenidoSlider == 1 && this.valorUf2 == 1) {
    //     this.alert60 = false;
    //   }
    //   else{
    //     const maxRoboContenido = 0.60 * this.homeData.valoresUf.contenidoSlider;
    //     // Verificar si this.valorUf2 supera el 60% de this.homeData.valoresUf.contenidoSlider
    //     if (this.homeData.infoBenef.roboContenido == true){
    //       if (this.valorUf2 > maxRoboContenido) {
    //         this.alert60 = true;
    //       } else {
    //         this.alert60 = false;
    //       }
    //     } else {
    //         this.alert60 = false;
    //     }
    //   }   

    //   if (this.valorUf2 > 6001) {
    //     this.valorUf2 = 6000;
    //     this.alertThree = true;
    //   } else {
    //     this.alertThree = false;
    //   }
    // },
    setValueEstructura() {
      if (this.valorUtilizarEstructura < 500) {
        swal({
          title: "Ups!",
          text: "No cumple con el mínimo de 500 UF",
          icon: "info",
          button: "Ok",
        });
      } else if (this.valorUtilizarEstructura > 22000) {
        swal({
          title: "Ups!",
          text: "El tope máximo es 22.000 UF",
          icon: "info",
          button: "Ok",
        });
      } else {
        this.homeData.valoresUf.estructuraSlider = this.valorUtilizarEstructura;
        this.homeData.valoresUf.estructuraInput = this.valorUtilizarEstructura;
        this.calculatePrice('no', 'no');
        this.dialog = false;
      }
    },
    setValueContenido() {
      if (this.valorUtilizarContenido < 100) {
        swal({
          title: "Ups!",
          text: "El tope mínimo es 100 UF",
          icon: "info",
          button: "Ok",
        });
      } else if (this.valorUtilizarContenido > 10000) {
        swal({
          title: "Ups!",
          text: "El tope máximo es 10.000 UF",
          icon: "info",
          button: "Ok",
        });
      } else {
        this.homeData.valoresUf.contenidoSlider = this.valorUtilizarContenido;
        this.homeData.valoresUf.contenidoInput = this.valorUtilizarContenido;
        this.calculatePrice('no', 'no');
        this.dialog2 = false;
      }
    },
    async calculatePrice(estructuraChange, contenidoChange) {

      if (estructuraChange != 'no') {

        if (estructuraChange) {
          this.homeData.valoresUf.estructuraSlider = this.homeData.valoresUf.estructuraInput;
        } else {
          this.homeData.valoresUf.estructuraInput = this.homeData.valoresUf.estructuraSlider;
        }

      }

      if (contenidoChange != 'no') {

        if (contenidoChange) {
          this.homeData.valoresUf.contenidoSlider = this.homeData.valoresUf.contenidoInput;
        } else {
          this.homeData.valoresUf.contenidoInput = this.homeData.valoresUf.contenidoSlider;
        }

      }

      const ufShield = await ShieldService.getUf();
      const uf = ufShield.data;

      // There we have to change between differeents types of coverages
      let incendioEstructura = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'estructura' ? 'building_fire' : null
      let incendioContenido = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'contenido' ? 'content_fire' : null
      let sismoEstructura = this.homeData.infoBenef.sismoEstructura == true ? 'building_earthquake' : null
      let sismoContenido = this.homeData.infoBenef.sismoContenido == true ? 'content_earthquake' : null
      let roboContenido = this.homeData.infoBenef.roboContenido == true ? 'content_theft' : null

      const userCoverages = [incendioEstructura, incendioContenido, sismoEstructura, sismoContenido, roboContenido]
      const userInputCoverages = userCoverages.filter(cov => cov != null)

      let gastosMedicos = this.homeData.infoBenef.gastosMedicos == true ? 'medical_refund' : null
      let asistenciaMedica = this.homeData.infoBenef.asistenciaMedica == true ? 'telemedicine_assistance' : null
      let seguroMascota = this.homeData.infoBenef.seguroMascota == true ? 'pet_assistance' : null
      const userAggregates = [gastosMedicos, asistenciaMedica, seguroMascota]
      const userInputAggregates = userAggregates.filter(agg => agg != null)

      const userInput = {
        usrCoverages: userInputCoverages,
        usrAggregates: userInputAggregates,
        usrCapitals: {
          "building": this.homeData.valoresUf.estructuraSlider,
          "content": this.homeData.valoresUf.contenidoSlider,
          "content_theft": this.homeData.valoresUf.contenidoSlider * 0.6 // change 60%
        }
      }
      const results = Dynamic.calculate(userInput);
      const options = Dynamic.calculateAllOptions(userInput);

      if (this.homeData.discount.active) {
        this.homeData.valores.valorIncendioEstructura = (options.coverages.find(cov => cov.coverage == 'building_fire')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorIncendioContenido = (options.coverages.find(cov => cov.coverage == 'content_fire')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorSismoEstructura = (options.coverages.find(cov => cov.coverage == 'building_earthquake')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorSismoContenido = (options.coverages.find(cov => cov.coverage == 'content_earthquake')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorRoboContenido = (options.coverages.find(cov => cov.coverage == 'content_theft')?.coveragePrice ?? 0) * this.homeData.discount.value;

        this.homeData.valores.valorIncendioEstructuraUF = (this.homeData.valores.valorIncendioEstructura / 12)  // Valores Uf 
        this.homeData.valores.valorIncendioContenidoUF = (this.homeData.valores.valorIncendioContenido / 12)  // Valores Uf 
        this.homeData.valores.valorSismoEstructuraUF = (this.homeData.valores.valorSismoEstructura / 12)  // Valores Uf 
        this.homeData.valores.valorSismoContenidoUF = (this.homeData.valores.valorSismoContenido / 12)  // Valores Uf 
        this.homeData.valores.valorRoboContenidoUF = (this.homeData.valores.valorRoboContenido / 12)  // Valores Uf 

        // this.homeData.valores.homeData.valores.valorIncendioEstructuraUF = (this.homeData.valores.valorIncendioEstructura / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.homeData.valores.valorIncendioContenidoUF = (this.homeData.valores.valorIncendioContenido / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.valorSismoEstructuraUF = (this.homeData.valores.valorSismoEstructura / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.valorSismoContenidoUF = (this.homeData.valores.valorSismoContenido / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.valorRoboContenidoUF = (this.homeData.valores.valorRoboContenido / 12) * this.homeData.discount.value; // Valores Uf 

        // Here we capture the value for each coverage
        // And the output is the value on CLP
        this.homeData.valores.valorIncendioEstructura = ((this.homeData.valores.valorIncendioEstructura * uf) / 12)
        this.homeData.valores.valorIncendioContenido = ((this.homeData.valores.valorIncendioContenido * uf) / 12)
        this.homeData.valores.valorSismoEstructura = ((this.homeData.valores.valorSismoEstructura * uf) / 12)
        this.homeData.valores.valorSismoContenido = ((this.homeData.valores.valorSismoContenido * uf) / 12)
        this.homeData.valores.valorRoboContenido = ((this.homeData.valores.valorRoboContenido * uf) / 12)
        // this.homeData.valores.valorIncendioEstructura = ((this.homeData.valores.valorIncendioEstructura * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorIncendioContenido = ((this.homeData.valores.valorIncendioContenido * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorSismoEstructura = ((this.homeData.valores.valorSismoEstructura * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorSismoContenido = ((this.homeData.valores.valorSismoContenido * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorRoboContenido = ((this.homeData.valores.valorRoboContenido * uf) / 12) * this.homeData.discount.value;

        this.homeData.priceUF = (Number(results.total / 12)) * this.homeData.discount.value;
      } else {
        this.homeData.valores.valorIncendioEstructura = options.coverages.find(cov => cov.coverage == 'building_fire')?.coveragePrice ?? 0
        this.homeData.valores.valorIncendioContenido = options.coverages.find(cov => cov.coverage == 'content_fire')?.coveragePrice ?? 0
        this.homeData.valores.valorSismoEstructura = options.coverages.find(cov => cov.coverage == 'building_earthquake')?.coveragePrice ?? 0
        this.homeData.valores.valorSismoContenido = options.coverages.find(cov => cov.coverage == 'content_earthquake')?.coveragePrice ?? 0
        this.homeData.valores.valorRoboContenido = options.coverages.find(cov => cov.coverage == 'content_theft')?.coveragePrice ?? 0

        this.homeData.valores.valorIncendioEstructuraUF = this.homeData.valores.valorIncendioEstructura / 12 // Valores Uf 
        this.homeData.valores.valorIncendioContenidoUF = this.homeData.valores.valorIncendioContenido / 12 // Valores Uf 
        this.homeData.valores.valorSismoEstructuraUF = this.homeData.valores.valorSismoEstructura / 12 // Valores Uf 
        this.homeData.valores.valorSismoContenidoUF = this.homeData.valores.valorSismoContenido / 12 // Valores Uf 
        this.homeData.valores.valorRoboContenidoUF = this.homeData.valores.valorRoboContenido / 12 // Valores Uf 

        // Here we capture the value for each coverage
        // And the output is the value on CLP
        this.homeData.valores.valorIncendioEstructura = (this.homeData.valores.valorIncendioEstructura * uf) / 12
        this.homeData.valores.valorIncendioContenido = (this.homeData.valores.valorIncendioContenido * uf) / 12
        this.homeData.valores.valorSismoEstructura = (this.homeData.valores.valorSismoEstructura * uf) / 12
        this.homeData.valores.valorSismoContenido = (this.homeData.valores.valorSismoContenido * uf) / 12
        this.homeData.valores.valorRoboContenido = (this.homeData.valores.valorRoboContenido * uf) / 12

        this.homeData.priceUF = Number(results.total / 12)
      }

      this.homeData.priceCLP = (this.homeData.priceUF * uf)
    },
    handleScroll() {
      // const footer = this.$el;
      // const footerOffset = footer.offsetTop;
      // const scrollTop = window.scrollY || window.pageYOffset;

      // if (scrollTop > footerOffset) {
      //   this.isSticky = true;
      // } else {
      //   this.isSticky = false;
      // }
    },
    formatDiscount() {
      if (this.homeData.couponId) {
        this.homeData.couponId = this.homeData.couponId.replace(/\s+/g, '').toUpperCase();
      }
    },
    async SendCouponID() {

      if (this.homeData.couponId == 'PR0M010H06VRFUIL') {
        this.homeData.discount.value = 0.9;
      } else if (this.homeData.couponId == 'PR0M015H06VRFUIL') {
        this.homeData.discount.value = 0.85;
      } else {
        this.homeData.discount.value = 1;
      }

      this.homeData.discount.active = true;

      if (this.homeData.couponId == 'PR0M010H06VRFUIL' || this.homeData.couponId == 'PR0M015H06VRFUIL') {
        this.homeData.discount.active == true;
        this.calculatePrice('no', 'no')
        swal({
          title: "Código válido!",
          text: "Descuento aplicado correctamente.",
          icon: "success",
          button: "Ok",
        });
      } else {
        this.homeData.discount.active = false;
        this.homeData.discount.value = 1;
        this.homeData.couponId = null;
        this.calculatePrice('no', 'no')
        swal({
          title: "Código inválido!",
          text: "Ingrese un código de descuento válido.",
          icon: "error",
          button: "Ok",
        });
      }
    },
  }
}

</script>

<style lang="scss" scoped>
#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.col div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div form.v-form div.plan-container div.container form.v-form div div div.v-input.slider.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider div.v-input__control div.v-input__slot div.v-slider.v-slider--horizontal.theme--light div.v-slider__track-container {
  position: absolute !important;
  border-radius: 5px !important;
}

#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.col div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div form.v-form div.plan-container div.container form.v-form div div div.v-input.slider.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider div.v-input__control div.v-input__slot div.v-slider.v-slider--horizontal.theme--light div.v-slider__track-container div.v-slider__track-background.primary.lighten-3 {
  background-color: #808095 !important;
  border-color: #7e80cd !important;
}

::v-deep .v-slider__track-container {
  clip-path: border-box !important;
  height: 6px !important;
}

::v-deep .v-slider__track-container {
  position: absolute !important;
  border-radius: 5px !important;
}

::v-deep .v-slider__thumb {
  height: 14px !important;
  width: 14px !important;
}

.box-parrafo {
  margin-bottom: 10px !important;
}

.textoSub {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
}

.desc-parrafo {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #4F74E3;
  justify-content: center;
  margin-top: 2%;
}

.text-recommend {
  color: var(--AD-Primario-1, #2D387C);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-top: 2%;
  line-height: 23.5px;
}

.bluebox-recommend {
  // height: 80px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(79, 116, 227, 0.08);
  padding: 2%;
}

.butonMetros {
  border-radius: 6px;
  border: 1px solid #D6D6D6;
  background: #FFF;
  max-width: 174px;
  max-height: 39px;
}

.labelValorUF {
  color: var(--AD-Texto-1, #333);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.5px;
  letter-spacing: -0.16px;
}

.labelTituloInfo {
  color: var(--AD-Primario-1, #2D387C);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.text-tittle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.text-tittle-coberturas {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.div-uf {
  margin: 10px;
  padding: 9px 33px;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #7b7979;
  background: #FFF;
  margin-right: 4%;
  margin-left: 40%;
  height: 3%;
  display: flex;
  width: 232px;
  height: 48px;
}

.div-uf2 {
  margin: 10px;
  padding: 9px 33px;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #7b7979;
  background: #FFF;
  margin-left: 43.5%;
  height: 3%;
  display: flex;
  width: 232px;
  height: 48px;
}

.slider {
  display: flex;
  width: 539px;
  height: 7px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  padding-right: 25%;
  padding-left: 25%;
  border-radius: 10px;
}

.box-conteiner {
  background: linear-gradient(127.99deg, #F9F9FF -14.32%, #DADCFB 137.36%);
  border-radius: 8px;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 140px;
  max-width: 925px;
  // opacity: 0.56;
}

.purple-image {
  margin-right: 2%;
  width: 10%;
  padding-left: 1.5%;
  padding-top: 1.5%;
  margin-left: 2%;
  margin-top: 1%;
  background: var(--AD-Secundario-1, #4F74E3);
  border-radius: 6px;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  position: relative;
}

.white-image {
  margin-right: 4%;
  margin-left: 1%;
  margin-top: 1%;
  margin-bottom: 4%;
  // background: var(--AD-Secundario-1, #4F74E3); 
  border-radius: 6px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  position: relative;
}

.imagen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.text-subTittle {
  color: var(--AD-Primario-1, #070783);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.text-content {
  color: var(--AD-Texto-1, #333);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  padding-bottom: 3%;
  padding-top: 2%;
}

.text-content-uf {
  color: var(--AD-Texto-2, #4F74E3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  padding-bottom: 3%;
}

.box-incluido {
  background: var(--AD-Primario-2, #4F74E3);
  border-radius: 8px;
  padding-right: 4%;
  padding-left: 4%;
  padding-top: 6%;
  padding-bottom: 6%;
  color: #FFF;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: nohermal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
}

// @media only screen and (max-width: 1700px) {
// .box-incluido{
//     background: var(--AD-Primario-2, #4F74E3); 
//     border-radius: 8px; 
//     padding-right: 4%; 
//     padding-left: 4%;  
//     padding-top: 6%; 
//     padding-bottom: 6%; 
//     color: #FFF; 
//     text-align: center; 
//     font-family: Arial, Helvetica, sans-serif; 
//     font-size: 16px; 
//     font-style: nohermal; 
//     font-weight: 600; 
//     line-height: 16px; /* 100% */

// }
// }

.box-incluir {
  background: var(--AD-Primario-2, #4F74E3);
  border-radius: 8px;
  // padding-right: 3%;
  // padding-left: 3%;
  padding-top: 3%;
  padding-bottom: 4%;
  margin-left: 3.3%;
  color: #FFF;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: nohermal;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 130px;
}

.box-incluir-1 {
  background: var(--AD-Primario-2, #4F74E3);
  border-radius: 8px;
  // padding-right: 3%;
  // padding-left: 3%;
  padding-top: 3%;
  padding-bottom: 4%;
  color: #FFF;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: nohermal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 130px;
}

.button-aplicar {
  width: 70px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143.34%;
  color: white;
  text-transform: capitalize;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.tu-plan {
  margin-left: 10%;
  margin-right: 40%;
  color: var(--AD-Primario-1, #2D387C);
  font-size: 16px;
  font-weight: 700;
}

.button-continuar {
  color: white;
  display: flex;
  width: 252px;
  padding: 12px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.button-ayuda {
  display: flex;
  width: 99px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--AD-Primario-1, #2D387C);
  text-transform: none !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
}

@media only screen and (max-width: 800px) {

  .text-tittle {
    color: var(--AD-Primario-1, #2D387C);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  .text-tittle-coberturas {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #2D387C;
  }

  .div-uf {
    margin: 7%;
    margin-left: 30%;
    margin-right: 5%;
    border-radius: 6px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    display: flex;
    width: 123px;
    height: 28px;
    padding: 9px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .number-uf {
    color: var(--AD-Texto-1, #333);
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 30%;
  }

  .slider {
    display: flex;
    width: 539px;
    height: 7px;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    padding-right: 25%;
    padding-left: 25%;
    margin-top: -5%;
    margin-bottom: 5%;
    border-radius: 10px;
    color: #4F74E3;
    background-color: #4F74E3;

  }
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}


@media only screen and (max-width: 1500px) {
  .precioPlan {
    padding-bottom: 4%;
    margin-top: -10%;
    background-color: rgb(255, 255, 255);
  }
}

@media only screen and (min-width: 1500px) {
  .precioPlan {
    padding-bottom: 3%;
    margin-top: -7%;
    background-color: rgb(255, 255, 255);
  }
}
</style>