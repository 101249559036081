<template>
  <v-container>

    <NavBar v-if="tipoPropiedad == 'casa'" :step="5" />
    <NavBar v-if="tipoPropiedad == 'departamento'" :step="6" />

    <Titulo></Titulo>

    <br>
    <br>
    <br>
    <br>

    <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C; margin-top: 6%;
                    margin-bottom: 10%;">
      Lamentablemente no podemos continuar con el proceso.

    </h3>

    <div class="text-center">
      Debido a la condición de tu propiedad, en este momento no podemos asegurarte. <br>
      Si tienes dudas o deseas contactarte con nosotros, por favor haz clic aquí.
    </div>

    <div class="button-container" style="margin-top: 10%;">
      <v-btn style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 50%; height: 120%;
                    margin-left: 25%; margin-right: 25%;" @click="validate()">
        LLAMAR al 800 391 300
      </v-btn>
    </div>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import { googleMapsApiKey } from '../google-maps-config.js';
import locationService from "../services/location.service";


import arrow from '@/assets/images/arrow.png';


// import GoogleMap from './components/GoogleMap.vue';

export default {
  name: "Error2",
  components: { Titulo, NavBar },
  data: () => ({
    tipoPropiedad: localStorage.getItem('tipoPropiedad'),
    mapView: '',
    arrow,
    googleMapsApiKey,
    center: { lat: -33.4489, lng: -70.6693, }, // Cambia las coordenadas según tus necesidades
    zoom: 12, // Ajusta el nivel de zoom según tus necesidades
    stepperColor: '#516EB3',
    screen: window.screen.width,
    stepId: null,
    userData: {
      direccion: null,
      name: localStorage.getItem("name"),
      surname: localStorage.getItem("surname"),
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      address: null,
      email: localStorage.getItem("email"),
      phoneNumber: localStorage.getItem("phoneNumber"),
      hasAcode: null,
      couponId: null,
      dates: null,
    },
    petData: {
      species: {
        name: null,
      },
      breed: null,
      petName: null,
      age: {
        label: null,
      },
      sex: {
        label: null,
      },
      hasMicrochip: false,
    },
    e1: 4,
    month: null,
    day: null,
    year: null,
    months: [],
    days: [],
    yars: [],
    valid: false,
    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    regionRules: [(v) => !!v || "Ingresa la region en la que vives con tu mascota",],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives",],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres"
    ],

    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    regions: [],
    communes: [],
    newRut: "",
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
  }),
  // created() {
  // this.center = { lat: 37.7749, lng: -122.4194 }; // Establece el centro del mapa inicial
  // },
  mounted() {
    this.petData.species.name = localStorage.getItem('species')
    this.petData.breed = localStorage.getItem('breed')
    this.petData.name = localStorage.getItem('namepet')
    this.petData.age.label = localStorage.getItem('ageLabel')
    this.petData.sex.label = localStorage.getItem('sexLabel')
    this.petData.hasMicrochip = false
    this.stepId = localStorage.getItem('stepId')


    this.userData.direccion = localStorage.getItem("direccion")

    if (this.screen > 800) {
      this.mapView = 'mapStyle'
    } else {
      this.mapView = 'mapStyle2'
    }
    this.getRegions();
    this.getMonths();
    this.getYears();
  },
  methods: {
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    selectGender(gender) {
      this.userData.gender = gender;
    },
    validateGender() {
      if (this.userData.gender === true) {
        return false
      } else {
        return true;
      }
    },
    back() {
      this.$emit("back");
    },
    SaveDates(dates) {
      this.$refs.menu.save(dates)
    },
    validate() {
      // if (this.$refs.form.validate()) {
      //   localStorage.setItem("name", this.userData.name);
      //   localStorage.setItem("surname", this.userData.surname);
      //   localStorage.setItem("email", this.userData.email);
      //   localStorage.setItem("phoneNumber", this.userData.phoneNumber);
      //   localStorage.setItem("dates", this.userData.dates);

      //   localStorage.setItem("rut", this.userData.rut);
      //   localStorage.setItem("serialNumber", this.userData.serialNumber);
      //   localStorage.setItem("direccion", this.userData.direccion);
      //   localStorage.setItem("commune", this.userData.commune.id);
      //   localStorage.setItem("region", this.userData.region.id);

      //   window.location.pathname = "/resumen";

      // }
      // this.$router.push({path: "/datos"}) ;
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.month) {
        this.loadingDays = true;
        this.day = null;
        this.days = await dateService.getDays(
          this.month.name
        );
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async autoCompletado(place) {
      if (place.geometry && place.geometry.location) {
        this.userData.direccion = place.formatted_address;
        this.center.lat = place.geometry.location.lat();
        this.center.lng = place.geometry.location.lng();
      }
    },
    async change() {

      const location = await locationService.getLocationByCity(this.userData.direccion)
      this.center.lat = Number(location[0].lat)
      this.center.lng = Number(location[0].lon)

      var values;

      try {
        values = [
          this.userData.name,
          this.userData.surname,
          this.userData.serialNumber,
          this.userData.rut,
          this.userData.region.name,
          this.userData.commune.name,
          this.userData.address,
          this.userData.email,
          this.userData.phoneNumber,
          this.petData.species.name,
          this.petData.breed,
          this.petData.name,
          this.petData.age == null ? '' : this.petData.age.label,
          this.petData.sex == null ? '' : this.petData.sex.label,
          this.petData.hasMicrochip,
        ]
        var carro = await ShieldService.createStep(values, 4, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }

    }
  },
};
</script>

<style lang="scss">
.texto {
  width: 624px;
  height: 104px;
  opacity: 0.75;
  color: var(--AD-Texto-1, #333);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.5px;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #2D387C !important;
}

.v-textField {
  box-sizing: border-box;
  height: 48px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelSelect {
  color: var(--AD-Texto-1, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.mapStyle {
  width: 400px;
  height: 48px;
  background: #FFFFFF;
  border: 2px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 5px;
  margin: auto;
}

.mapStyle2 {
  max-width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 2px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 5px;
  margin: auto;
}
</style>