<template>
  <v-container style="margin: auto;">

    <v-form ref="form" v-model="valid">
      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'estructura'">

        <h3 class="text-center text-tittle" style="max-width: 300px; margin: auto;">
          Ingresa el monto en UF por el que quieres asegurar tu hogar:
        </h3>

        <p></p>
        <p class="textoSub">Puedes usar la barra para elegir el monto o el botón ayuda para guiarte.</p>

        <v-row class="ufs" style="padding-top: 2%; align-items: center;">

          <div class="text-center div-uf">
            <p style="padding-top:20% ;">UF</p>
            <v-text-field style="text-align: center;" class="number-uf" v-model="homeData.valoresUf.estructuraInput"
              min="500" max="22000" @input="calculatePrice(true, 'no')" :rules="ufOneRules"></v-text-field>
          </div>

          <v-dialog v-model="dialog" width="600px">

            <v-card flat style="box-shadow: none !important;">

              <div style="background: var(--AD-Primario-2, #4F74E3);">

                <v-card-actions @click="dialog = false" style="color: white; margin-left: 95%;">
                  X
                </v-card-actions>

                <v-row style="margin-left: 10%;">

                  <div class="white-image">
                    <img :src="imagenRecomendacion" alt="Image 1">
                  </div>

                  <v-card-tittle style="margin:auto; color: white;">
                    <span>Te ayudamos a calcular los metros cuadrados</span>
                  </v-card-tittle>

                </v-row>

              </div>

              <v-card-text>

                <v-col>

                  <br>

                  <div class="labelTituloInfo">
                    ¿Cuantos metros cuadrados construidos tiene tu casa o departamento?
                  </div>

                  <br>

                  <v-container fluid>
                    <v-layout justify-center align-center>

                      <v-col>

                        <v-flex xs10>
                          <v-text-field v-model="inputCalculoEstructura" :rules="metersRules" solo outlined
                            class="butonMetros">
                          </v-text-field>
                        </v-flex>

                      </v-col>

                      <v-col>

                        <v-flex xs6>
                          <v-btn @click="calculateEstructuraRecommend()" :disabled="!isInputValidEstructura"
                            style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white; height: 50px; margin-top: 10%;">
                            Calcular
                          </v-btn>
                        </v-flex>

                        <v-flex xs6>
                          <v-btn :disabled="!valorUtilizarEstructura || !isInputValidEstructura"
                            @click="setValueEstructura()"
                            style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white; height: 50px; margin-top: 10%; margin-left: 8%;">
                            Utilizar
                          </v-btn>
                        </v-flex>

                      </v-col>

                    </v-layout>
                  </v-container>

                  <br>

                  <div style="border-radius: 4px; background: rgba(79, 116, 227, 0.08); text-align: center;"
                    class="bluebox-recommend">
                    <p></p>
                    Te recomendamos asegurar tu casa por:
                    <div v-if="valorUtilizarEstructura != null" class="labelValorUF">
                      {{ valorUtilizarEstructura }} UF
                    </div>

                  </div>

                  <br>

                </v-col>

              </v-card-text>

            </v-card>

          </v-dialog>

          <v-btn class="button-ayuda" style="border-radius: 33px; background: #E0E1FF;" @click="dialog = true">
            Ayuda
          </v-btn>

        </v-row>

        <div style="display: flex; margin: center;">
          <v-slider class="slider" color="#4F74E3" v-model="homeData.valoresUf.estructuraSlider" min="500" max="22000"
            @change="calculatePrice(false, 'no')"></v-slider>
        </div>

        <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex xs5>
              <label>UF 500</label>
            </v-flex>

            <v-flex xs3>
              <label>UF 22.000</label>
            </v-flex>
          </v-layout>
        </v-container>

        <h3 class="text-center text-tittle-coberturas" style="max-width: 300px; margin: auto;">
          Para proteger tu hogar tenemos las siguientes coberturas:
        </h3>

      </div>


      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'estructura'"
        class="box-conteiner">

        <v-card class="mx-auto box-conteiner1 " max-width="344">

          <v-row>

            <div class="purple-image">
              <img :src="incendioEstructura" alt="Image 1" class="imagen">
            </div>

            <v-card-title class="text-subTittle1">
              Incendio Estructura
            </v-card-title>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
                class="imagen-arrow" @click="show = !show">
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>

            </v-card-actions>

          </v-row>

          <v-expand-transition>

            <div v-show="show">



              <v-card-text class="text-content1">
                Cubre los daños materiales de tu hogar <br>
                por la acción directa del incendio y los <br>
                que sean una consecuencia inmediata <br>
                del incendio como los causados por el <br>
                calor, el humo, el vapor o lo que se usó <br>
                para extinguir o contener el incendio; y <br>
                las demoliciones que sean necesarias o <br>
                indicadas por la autoridad competente.
              </v-card-text>

              <div class="valor-agregado">
                + {{ formatPrice(homeData.valores.valorIncendioEstructura) }}
              </div>

            </div>

          </v-expand-transition>

          <v-row>

            <!-- <v-checkbox class="checkbox" v-model="homeData.infoBenef.incendioEstructura" @change="calculatePrice()">
            </v-checkbox> -->

            <div class="box-incluir">
              Incluido
            </div>

          </v-row>

        </v-card>

      </div>


      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'estructura'"
        class="box-conteiner">

        <v-card class="mx-auto box-conteiner1 " max-width="344">

          <v-row>

            <div class="purple-image">
              <img :src="sismoEstructura" alt="Image 2" class="imagen">
            </div>

            <v-card-title class="text-subTittle1">
              Sismo Estructura
            </v-card-title>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
                class="imagen-arrow" @click="show1 = !show1">
                <v-icon>{{ show1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>

            </v-card-actions>

          </v-row>

          <v-expand-transition>

            <div v-show="show1">



              <v-card-text class="text-content1">
                Cubre los daños materiales que sufra tu <br> hogar por la acción
                directa e inmediata <br> de sismo.Contempla la cobertura de <br>retiro
                de escombros y de inhabitabilidad,<br> que otorga un monto mensual
                temporal <br>en caso que tu hogar sea declarado<br> inhabitable y
                acredites que haz incurrido<br> en un gasto de arrendamiento.<br>
                No incluye cobertura de contenido de tu<br> hogar. Si lo
                necesitas la puedes agregar <br> como adicional más adelante.
              </v-card-text>

              <div class="valor-agregado">
                + {{ formatPrice(homeData.valores.valorSismoEstructura) }}
              </div>

            </div>

          </v-expand-transition>

          <v-row>

            <v-checkbox class="checkbox" v-model="homeData.infoBenef.sismoEstructura"
              @change="calculatePrice('no', 'no')">
            </v-checkbox>

            <div v-if="!homeData.infoBenef.sismoEstructura" class="box-incluir">
              Agregar
            </div>

            <div v-if="homeData.infoBenef.sismoEstructura" class="box-incluir">
              Quitar
            </div>

          </v-row>

        </v-card>

      </div>


      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'">

        <h3 class="text-center text-tittle" style="max-width: 300px; margin: auto;">
          Ingresa el monto en UF por el que quieres asegurar tu hogar:
        </h3>

        <p></p>
        <p class="textoSub">Puedes usar la barra para elegir el monto o el botón ayuda para guiarte.</p>

        <v-row class="ufs" style="padding-top: 2%; align-items: center;">

          <div class="text-center div-uf">
            <p style="margin-top: 20%;">UF</p>
            <v-text-field style="text-align: center;" class="number-uf" v-model="homeData.valoresUf.contenidoInput"
              min="100" max="10000" @input="calculatePrice('no', true)" :rules="ufTwoRules"></v-text-field>
          </div>

          <v-dialog v-model="dialog2" width="600px">

            <v-card>

              <div style="background: var(--AD-Primario-2, #4F74E3);">

                <v-card-actions @click="dialog2 = false" style="color: white; margin-left: 95%;">
                  X
                </v-card-actions>

                <v-row style="margin-left: 10%;">

                  <div class="white-image">
                    <img :src="imagenRecomendacion" alt="Image 1">
                  </div>

                  <v-card-tittle style="margin:auto; color: white;">
                    <span>Te ayudamos a calcular los metros cuadrados</span>
                  </v-card-tittle>

                </v-row>

              </div>

              <v-card-text>

                <v-col>

                  <br>

                  <div class="labelTituloInfo">
                    ¿Cuantos metros cuadrados construidos tiene tu casa o departamento?
                  </div>

                  <br>

                  <v-container fluid>
                    <v-layout justify-center align-center>

                      <v-col>

                        <v-flex xs10>
                          <v-text-field v-model="inputCalculoContenido" :rules="metersRules" solo outlined
                            class="butonMetros">
                          </v-text-field>
                        </v-flex>

                      </v-col>

                      <v-col>

                        <v-flex xs6>
                          <v-btn @click="calculateContenidoRecommend()" :disabled="!isInputValidContenido"
                            style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white; height: 50px; margin-top: 10%;">
                            Calcular
                          </v-btn>
                        </v-flex>

                        <v-flex xs6>
                          <v-btn :disabled="!valorUtilizarContenido || !isInputValidContenido"
                            @click="setValueContenido()"
                            style="border-radius: 6px; background: var(--AD-Primario-1, #2D387C); color: white; height: 50px; margin-top: 10%; margin-left: 8%;">
                            Utilizar
                          </v-btn>
                        </v-flex>

                      </v-col>

                    </v-layout>
                  </v-container>

                  <br>

                  <div style="border-radius: 4px; background: rgba(79, 116, 227, 0.08); text-align: center;"
                    class="bluebox-recommend">
                    <p></p>
                    Te recomendamos asegurar tu casa por:
                    <div v-if="valorUtilizarContenido != null" class="labelValorUF">
                      {{ valorUtilizarContenido }} UF
                    </div>

                  </div>

                  <br>

                </v-col>

              </v-card-text>

            </v-card>

          </v-dialog>

          <!-- <v-btn class="button-ayuda" style="border-radius: 33px; background: #E0E1FF;" @click="dialog2 = true">
            Ayuda
          </v-btn> -->

        </v-row>

        <div style="display: flex; margin: center;">
          <v-slider class="slider" color="#4F74E3" v-model="homeData.valoresUf.contenidoSlider" min="100" max="10000"
            @change="calculatePrice('no', false)"></v-slider>
        </div>

        <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex xs5>
              <label>UF 100</label>
            </v-flex>

            <v-flex xs3>
              <label>UF 10.000</label>
            </v-flex>
          </v-layout>
        </v-container>

        <h3 class="text-center text-tittle-coberturas" style="max-width: 300px; margin: auto;">
          Para proteger tu hogar tenemos las siguientes coberturas:
        </h3>

      </div>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner">

        <v-card class="mx-auto box-conteiner1 " max-width="344">

          <v-row>

            <div class="purple-image">
              <img :src="incendioContenido" alt="Image 3" class="imagen">
            </div>

            <v-card-title class="text-subTittle1">
              Incendio Contenido
            </v-card-title>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
                class="imagen-arrow" @click="show2 = !show2">
                <v-icon>{{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>

            </v-card-actions>

          </v-row>

          <v-expand-transition>

            <div v-show="show2">



              <v-card-text class="text-content1">
                Cubre los daños que sufran los bienes <br>
                se encuentren al interior de tu hogar y <br>
                que no forman parte de su estructura -<br>
                muebles, televisores, electrodomésticos, <br>
                etc., por la acción directa del incendio y <br>
                los que sean una consecuencia <br>
                inmediata del incendio como los <br>
                causados por el calor, el humo, el vapor <br>
                o lo que se usó para extinguir o contener <br>
                el incendio.
              </v-card-text>

              <div class="valor-agregado">
                + {{ formatPrice(homeData.valores.valorIncendioContenido) }}
              </div>

            </div>

          </v-expand-transition>

          <v-row>

            <!-- <v-checkbox class="checkbox" v-model="homeData.infoBenef.incendioContenido" @change="calculatePrice()">
            </v-checkbox> -->

            <img :src="ticket" alt="ticket"
              style="width: 8px; height: 6px; margin-top: 4%; margin-left: 20%; margin-right: -12%;">
            <div class="box-incluir">
              Incluido
            </div>

          </v-row>

        </v-card>

      </div>

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner">

        <v-card class="mx-auto box-conteiner1 " max-width="344">

          <v-row>

            <div class="purple-image">
              <img :src="sismoContenido" alt="Image 4" class="imagen">
            </div>

            <v-card-title class="text-subTittle1">
              Sismo Contenido
            </v-card-title>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
                class="imagen-arrow" @click="show3 = !show3">
                <v-icon>{{ show3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>

            </v-card-actions>

          </v-row>

          <v-expand-transition>

            <div v-show="show3">



              <v-card-text class="text-content1">
                Cubre los daños que sufran los bienes <br>
                por la acción directa e inmediata de <br>
                sismo que se encuentren al interior de tu <br>
                hogar y que no forman parte de su <br>
                estructura como muebles, televisores, <br>
                electrodomésticos, etc.
              </v-card-text>

              <div class="valor-agregado">
                + {{ formatPrice(homeData.valores.valorSismoContenido) }}
              </div>

            </div>

          </v-expand-transition>

          <v-row>

            <v-checkbox class="checkbox" v-model="homeData.infoBenef.sismoContenido"
              @change="calculatePrice('no', 'no')">
            </v-checkbox>

            <div v-if="!homeData.infoBenef.sismoContenido" class="box-incluir">
              Agregar
            </div>

            <div v-if="homeData.infoBenef.sismoContenido" class="box-incluir">
              Quitar
            </div>

          </v-row>

        </v-card>

      </div>

      <!-- <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner"> -->

      <div v-if="userData.recomendacion == 'estructura y contenido' || userData.recomendacion == 'contenido'"
        class="box-conteiner">
        <v-card class="mx-auto box-conteiner1 " max-width="344">
          <v-row>

            <div class="purple-image">
              <img :src="roboContenido" alt="Image 5" class="imagen">
            </div>

            <v-card-title class="text-subTittle1-Robo">
              Robo Contenido <br>(60% del contenido)
            </v-card-title>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
                class="imagen-arrow" @click="show4 = !show4">
                <v-icon>{{ show4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>

            </v-card-actions>

          </v-row>

          <v-expand-transition>

            <div v-show="show4">



              <v-card-text class="text-content1">
                Cubre por el monto asegurado los <br>
                bienes que tienes al interior de tu hogar <br>
                y que no forman parte de su estructura <br>
                como muebles, televisores, <br>
                electrodomésticos, etc en caso de robo <br>
                en tu hogar.
              </v-card-text>

              <v-card-text class="text-content-uf-mobile">
                Monto asegurable para tu contenido: {{ Number(this.homeData.valoresUf.contenidoSlider *
                  0.60).toFixed(0) }} UF.
              </v-card-text>

              <div class="valor-agregado">
                + {{ formatPrice(homeData.valores.valorRoboContenido) }}
              </div>

            </div>

          </v-expand-transition>

          <v-row>

            <v-checkbox class="checkbox" v-model="homeData.infoBenef.roboContenido"
              @change="calculatePrice('no', 'no')">
            </v-checkbox>

            <div v-if="!homeData.infoBenef.roboContenido" class="box-incluir">
              Agregar
            </div>

            <div v-if="homeData.infoBenef.roboContenido" class="box-incluir">
              Quitar
            </div>

          </v-row>

        </v-card>
      </div>

      <!-- <h3 class="text-center text-tittle" style="padding-top: 5%;">Asegura tu robo contenido por
        </h3> -->

      <!-- <v-row class="ufs" style="padding-top: 2%; align-items: center;"> -->

      <!-- <div class="text-center div-uf">
            <p style="margin-top: 20%;">UF</p>
            <v-text-field style="text-align: center;" class="number-uf" type="number" v-model="valorUf2" min="1" max="6000"
              @change="calculatePrice()" @input="updateValue3" :rules="ufThreeRules"></v-text-field>
          </div> -->

      <!-- <v-btn class="button-ayuda" style="border-radius: 33px; background: #E0E1FF;" @click="dialog2 = true">
            ayuda
          </v-btn> -->

      <!-- </v-row> -->

      <!-- <div style="display: flex; margin: center;">

          <v-slider class="slider" color="#4F74E3" v-model="valorUf2" min="1" max="6000"
            @change="calculatePrice()"></v-slider>

        </div> -->

      <!-- <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex xs5>
              <label>UF 1</label>
            </v-flex>

            <v-flex xs3>
              <label>UF 6.000</label>
            </v-flex>
          </v-layout>
        </v-container>

        <h3 class="text-center text-tittle-coberturas">
          Coberturas
        </h3> -->


      <!-- <v-card class="mx-auto box-conteiner1 " max-width="344">
          <v-row>

            <div class="purple-image">
              <img :src="roboContenido" alt="Image 5" class="imagen">
            </div>

            <v-card-title class="text-subTittle1">
              Robo Contenido (60% del contenido)
            </v-card-title>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn style="background-color: rgba(218, 220, 251, 0); border: none; box-shadow: none;"
                class="imagen-arrow" @click="show4 = !show4">
                <v-icon>{{ show4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>

            </v-card-actions>

          </v-row>

          <v-expand-transition>

            <div v-show="show4">

              

              <v-card-text class="text-content1">
                Cubre por el monto asegurado los <br>
                bienes que tienes al interior de tu hogar <br>
                y que no forman parte de su estructura <br>
                como muebles, televisores, <br>
                electrodomésticos, etc en caso de robo <br>
                en tu hogar.
              </v-card-text>

              <div class="valor-agregado">
                + {{ formatPrice(valorRoboContenido) }}
              </div>

            </div>

          </v-expand-transition>

          <v-row>

            <v-checkbox class="checkbox" v-model="homeData.infoBenef.roboContenido" @change="calculatePrice()">
            </v-checkbox>

            <div v-if="homeData.infoBenef.roboContenido == false" class="box-incluir">
              Agregar
            </div>

            <div v-if="homeData.infoBenef.roboContenido == true" class="box-incluir">
              Quitar
            </div>

          </v-row>

        </v-card> -->

      <!-- </div> -->

    </v-form>

    <div style="margin-bottom: 7%;">

      <h3 class="text-center text-tittle-coberturas" style="max-width: 300px; margin: auto; margin-top: 5%;">
        ¿Tienes un código de descuento promocional? Ingresalo aquí:
      </h3>

      <div style="margin-right: 10%; margin-left: 5%; margin-top: 10%; display:flex;">
        &nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;

        <v-text-field class="labelDescuento" label="Ingresa aquí tu código de descuento" v-model="homeData.couponId"
          @blur="formatDiscount()" maxlength="51" :rules="couponRules" solo>
        </v-text-field>

        <v-btn filled solo class="button-aplicar" style=" height: 50px; background-color:#2D387C;"
          @click="SendCouponID()">
          Aplicar
        </v-btn>
      </div>
      <p v-if="homeData.discount.active" class="desc-parrafo-mb">Descuento aplicado</p>
      <p v-if="!homeData.discount.active" class="desc-parrafo-mb">&nbsp;&nbsp;</p>
    </div>

    <br>

    <div :class="{ 'sticky-footer': isSticky }">

      <BotLine />
      <div class="tu-plan">
        Tu plan mensual:
        <v-row style="align-items: baseline;">
          <p style="font-size: 20px;">{{ formatPrice(homeData.priceCLP) }}</p>
          <p style="margin-top: 2%;margin-left: 8px; ">(UF {{ Number(homeData.priceUF).toFixed(2) }})</p>
        </v-row>
      </div>
    </div>
    <div class="button-container">
      <v-btn class="button-continuar"
        style="background-color: #2D387C; height: 43px; margin-left: 210px; margin-top: 10px;" @click="validate()">
        Continuar
      </v-btn>
    </div>
  </v-container>
</template>


<script>

import Dynamic from "../services/dynamic.service";
import ShieldService from "../services/shield.service";

import incendioEstructura from '@/assets/images/incendioEstructura.png';
import sismoEstructura from '@/assets/images/sismoEstructura.png';
import incendioContenido from '@/assets/images/incendioContenido.png';
import sismoContenido from '@/assets/images/sismoContenido.png';
import roboContenido from '@/assets/images/roboContenido.png';
import imagenRecomendacion from '@/assets/images/recomendacion.png';

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';

import arrow from '@/assets/images/back-arrow.png';
import elipse from '@/assets/images/elipse.png';
import ticket from '@/assets/images/ticket.png';

import BotLine from "@/components/BotLine.vue";

export default {
  name: "Planes",
  props: ["userData", "homeData", "servicesData"],
  components: { BotLine },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    isSticky: true,
    dialog: false,
    dialog2: false,

    // alertOne: false,
    // alertTwo: false,
    // alertThree: false,
    // alert60: false,

    inputCalculoEstructura: null,
    inputCalculoContenido: null,

    valorUtilizarEstructura: null,
    valorUtilizarContenido: null,

    show: false,
    show1: false,
    show2: false,
    show3: false,
    show4: false,

    incendioEstructura, //imagenes
    sismoEstructura,
    incendioContenido,
    sismoContenido,
    roboContenido,
    imagenRecomendacion,

    arrow,
    ticket,
    elipse,

    e1: 2,
    valid: false,
    stepperColor: '#516EB3',

    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',

    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres",
      // (v) => !!v || 'El código de descuento es requerido.',
      (v) => (v && !/\s/.test(v)) || 'El código de descuento no puede contener espacios en blanco.'
    ],
    metersRules: [
      (v) => !!v || "El valor es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    ufOneRules: [
      (v) => (v >= 500) || "El valor mínimo debe ser 500 UF",
      (v) => (v <= 22000) || "El valor máximo debe ser 22.000 UF"
    ],
    ufTwoRules: [
      (v) => (v >= 100) || "El valor mínimo debe ser 100 UF",
      (v) => (v <= 10000) || "El valor máximo debe ser 10.000 UF"
    ],
    ufThreeRules: [
      (v) => (v >= 0 && v <= 6000) || "El tope máximo es 6.000 UF"
    ],
  }),
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    this.calculatePrice('no', 'no');

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    isInputValidEstructura() {
      return this.metersRules.every(rule => rule(this.inputCalculoEstructura) === true);
    },
    isInputValidContenido() {
      return this.metersRules.every(rule => rule(this.inputCalculoContenido) === true);
    },
  },
  // watch: {
  //   'homeData.infoBenef.roboContenido'(newValue, oldValue) {
  //     // Este código se ejecutará cada vez que homeData.infoBenef.roboContenido cambie
  //     // Puedes llamar a updateValue3 u otras acciones aquí si lo deseas
  //     this.updateValue3();
  //   }
  // },
  methods: {
    async validate() {
      // if (this.alert60 == true){
      //   swal({
      //       title: "Atención!",
      //       text: "El valor asegurado para la cobertura de robo no puede superar el 60% del valor asegurado para la coberturas de contenido.",
      //       icon: "info",
      //       button: "Ok",
      //     }); 
      // } else {

      if (this.$refs.form.validate()) {

        if ((this.userData.recomendacion === 'estructura y contenido' && this.homeData.valoresUf.estructuraSlider >= this.homeData.valoresUf.contenidoSlider) || (this.userData.recomendacion !== 'estructura y contenido')) {

          if (this.userData.recomendacion === 'estructura y contenido' && this.homeData.valoresUf.contenidoSlider !== null || (this.userData.recomendacion !== 'estructura y contenido')) {

            const homeDataLocal = JSON.stringify(this.homeData);
            const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();
            localStorage.setItem('homeData', encryptedHomeDataLocal);

            try {

              await ShieldService.newCreateStep(this.userData, null, this.homeData, this.servicesData, 'Planes');

            } catch (error) {
              console.log("Cart Fail");
            }

            if (this.userData.email && this.activeCampaingUse == 'TRUE') {
              const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

              this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

              await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

              const servicesDataLocal = JSON.stringify(this.servicesData);
              const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
              localStorage.setItem('servicesData', encryptedServicesDataLocal);

            }

            this.$router.push({ path: "/beneficios" });

          } else {
            swal({
              title: "Atención!",
              text: "Debes ingresar un monto de UF a cubrir.",
              icon: "info",
              button: "Ok",
            });
          }
        } else if (this.homeData.valoresUf.contenidoSlider > this.homeData.valoresUf.estructuraSlider) {
          swal({
            title: "Atención!",
            text: "El monto asegurado no puede ser mayor que el de estructura, intenta con un monto menor.",
            icon: "info",
            button: "Ok",
          });
        }

      } else {

        if (this.userData.recomendacion == 'estructura' || this.userData.recomendacion == 'estructura y contenido') {
          if (this.homeData.valoresUf.estructuraSlider < 500 || this.homeData.valoresUf.estructuraInput < 500) {
            swal({
              title: "Atención!",
              text: "El monto mínimo a asegurar es 500 UF.",
              icon: "info",
              button: "Ok",
            });
          } else if (this.homeData.valoresUf.estructuraSlider > 22000 || this.homeData.valoresUf.estructuraInput > 22000) {
            swal({
              title: "Atención!",
              text: "El monto máximo a asegurar es 22.000 UF.",
              icon: "info",
              button: "Ok",
            });
          }
        }

        if (this.userData.recomendacion == 'contenido' || this.userData.recomendacion == 'estructura y contenido') {
          if (this.homeData.valoresUf.contenidoSlider < 100 || this.homeData.valoresUf.contenidoInput < 100) {
            swal({
              title: "Atención!",
              text: "El monto mínimo a asegurar es 100 UF.",
              icon: "info",
              button: "Ok",
            });
          } else if (this.homeData.valoresUf.contenidoSlider > 10000 || this.homeData.valoresUf.contenidoInput > 10000) {
            swal({
              title: "Atención!",
              text: "El monto máximo a asegurar es 10.000 UF.",
              icon: "info",
              button: "Ok",
            });
          }
        }

      }

    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async calculateEstructuraRecommend() {
      this.valorUtilizarEstructura = this.inputCalculoEstructura * 30
    },
    async calculateContenidoRecommend() {
      this.valorUtilizarContenido = this.inputCalculoContenido * 7
    },
    // async updateValue() {
    //   if (this.valorUf < 1000) {
    //     this.valorUf = 1000;
    //     this.alertOne = true;
    //   } else if (this.valorUf > 22000) {
    //     this.valorUf = 22000;
    //     this.alertOne = true;
    //   } else {
    //     this.alertOne = false;
    //   }
    // },
    // async updateValue2() {
    //   if (this.valorUf > 10000) {
    //     this.valorUf = 10000;
    //     this.alertTwo = true;
    //   } else {
    //     this.alertTwo = false;
    //   }
    // },
    // async updateValue3() {
    //   if (this.homeData.valoresUf.contenidoSlider == 1 && this.valorUf2 == 1) {
    //     this.alert60 = false;
    //   }
    //   else{
    //     const maxRoboContenido = 0.60 * this.homeData.valoresUf.contenidoSlider;
    //     // Verificar si this.valorUf2 supera el 60% de this.homeData.valoresUf.contenidoSlider
    //     if (this.homeData.infoBenef.roboContenido == true){
    //       if (this.valorUf2 > maxRoboContenido) {
    //         this.alert60 = true;
    //       } else {
    //         this.alert60 = false;
    //       }
    //     } else {
    //         this.alert60 = false;
    //     }
    //   }   

    //   if (this.valorUf2 > 6001) {
    //     this.valorUf2 = 6000;
    //     this.alertThree = true;
    //   } else {
    //     this.alertThree = false;
    //   }
    // },
    setValueEstructura() {
      if (this.valorUtilizarEstructura < 500) {
        swal({
          title: "Ups!",
          text: "No cumple con el mínimo de 500 UF",
          icon: "info",
          button: "Ok",
        });
      } else if (this.valorUtilizarEstructura > 22000) {
        swal({
          title: "Ups!",
          text: "El tope máximo es 22.000 UF",
          icon: "info",
          button: "Ok",
        });
      } else {
        this.homeData.valoresUf.estructuraSlider = this.valorUtilizarEstructura;
        this.homeData.valoresUf.estructuraInput = this.valorUtilizarEstructura;
        this.calculatePrice('no', 'no');
        this.dialog = false;
      }
    },
    setValueContenido() {
      if (this.valorUtilizarContenido < 100) {
        swal({
          title: "Ups!",
          text: "El tope mínimo es 100 UF",
          icon: "info",
          button: "Ok",
        });
      } else if (this.valorUtilizarContenido > 10000) {
        swal({
          title: "Ups!",
          text: "El tope máximo es 10.000 UF",
          icon: "info",
          button: "Ok",
        });
      } else {
        this.homeData.valoresUf.contenidoSlider = this.valorUtilizarContenido;
        this.homeData.valoresUf.contenidoInput = this.valorUtilizarContenido;
        this.calculatePrice('no', 'no');
        this.dialog2 = false;
      }
    },
    async calculatePrice(estructuraChange, contenidoChange) {

      if (estructuraChange != 'no') {

        if (estructuraChange) {
          if (this.homeData.valoresUf.estructuraInput > 22000) {
            swal({
              title: "Atención!",
              text: "El tope máximo es 22.000 UF",
              icon: "info",
              button: "Ok",
            });
          } else {
            this.homeData.valoresUf.estructuraSlider = this.homeData.valoresUf.estructuraInput;
          }
        } else {
          this.homeData.valoresUf.estructuraInput = this.homeData.valoresUf.estructuraSlider;
        }

      }

      if (contenidoChange != 'no') {

        if (contenidoChange) {
          if (this.homeData.valoresUf.contenidoInput > 10000) {
            swal({
              title: "Atención!",
              text: "El tope máximo es 10.000 UF",
              icon: "info",
              button: "Ok",
            });
          } else {
            this.homeData.valoresUf.contenidoSlider = this.homeData.valoresUf.contenidoInput;
          }
        } else {
          this.homeData.valoresUf.contenidoInput = this.homeData.valoresUf.contenidoSlider;
        }

      }

      const ufShield = await ShieldService.getUf();
      const uf = ufShield.data;

      // There we have to change between differeents types of coverages
      let incendioEstructura = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'estructura' ? 'building_fire' : null
      let incendioContenido = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'contenido' ? 'content_fire' : null
      let sismoEstructura = this.homeData.infoBenef.sismoEstructura == true ? 'building_earthquake' : null
      let sismoContenido = this.homeData.infoBenef.sismoContenido == true ? 'content_earthquake' : null
      let roboContenido = this.homeData.infoBenef.roboContenido == true ? 'content_theft' : null

      const userCoverages = [incendioEstructura, incendioContenido, sismoEstructura, sismoContenido, roboContenido]
      const userInputCoverages = userCoverages.filter(cov => cov != null)

      let gastosMedicos = this.homeData.infoBenef.gastosMedicos == true ? 'medical_refund' : null
      let asistenciaMedica = this.homeData.infoBenef.asistenciaMedica == true ? 'telemedicine_assistance' : null
      let seguroMascota = this.homeData.infoBenef.seguroMascota == true ? 'pet_assistance' : null
      const userAggregates = [gastosMedicos, asistenciaMedica, seguroMascota]
      const userInputAggregates = userAggregates.filter(agg => agg != null)

      const userInput = {
        usrCoverages: userInputCoverages,
        usrAggregates: userInputAggregates,
        usrCapitals: {
          "building": this.homeData.valoresUf.estructuraSlider,
          "content": this.homeData.valoresUf.contenidoSlider,
          "content_theft": this.homeData.valoresUf.contenidoSlider * 0.6 // change 60%
        }
      }
      const results = Dynamic.calculate(userInput);
      const options = Dynamic.calculateAllOptions(userInput);

      if (this.homeData.discount.active) {
        this.homeData.valores.valorIncendioEstructura = (options.coverages.find(cov => cov.coverage == 'building_fire')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorIncendioContenido = (options.coverages.find(cov => cov.coverage == 'content_fire')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorSismoEstructura = (options.coverages.find(cov => cov.coverage == 'building_earthquake')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorSismoContenido = (options.coverages.find(cov => cov.coverage == 'content_earthquake')?.coveragePrice ?? 0) * this.homeData.discount.value;
        this.homeData.valores.valorRoboContenido = (options.coverages.find(cov => cov.coverage == 'content_theft')?.coveragePrice ?? 0) * this.homeData.discount.value;

        this.homeData.valores.valorIncendioEstructuraUF = (this.homeData.valores.valorIncendioEstructura / 12)  // Valores Uf 
        this.homeData.valores.valorIncendioContenidoUF = (this.homeData.valores.valorIncendioContenido / 12)  // Valores Uf 
        this.homeData.valores.valorSismoEstructuraUF = (this.homeData.valores.valorSismoEstructura / 12)  // Valores Uf 
        this.homeData.valores.valorSismoContenidoUF = (this.homeData.valores.valorSismoContenido / 12)  // Valores Uf 
        this.homeData.valores.valorRoboContenidoUF = (this.homeData.valores.valorRoboContenido / 12)  // Valores Uf 

        // this.homeData.valores.homeData.valores.valorIncendioEstructuraUF = (this.homeData.valores.valorIncendioEstructura / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.homeData.valores.valorIncendioContenidoUF = (this.homeData.valores.valorIncendioContenido / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.valorSismoEstructuraUF = (this.homeData.valores.valorSismoEstructura / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.valorSismoContenidoUF = (this.homeData.valores.valorSismoContenido / 12) * this.homeData.discount.value; // Valores Uf 
        // this.homeData.valores.valorRoboContenidoUF = (this.homeData.valores.valorRoboContenido / 12) * this.homeData.discount.value; // Valores Uf 

        // Here we capture the value for each coverage
        // And the output is the value on CLP
        this.homeData.valores.valorIncendioEstructura = ((this.homeData.valores.valorIncendioEstructura * uf) / 12)
        this.homeData.valores.valorIncendioContenido = ((this.homeData.valores.valorIncendioContenido * uf) / 12)
        this.homeData.valores.valorSismoEstructura = ((this.homeData.valores.valorSismoEstructura * uf) / 12)
        this.homeData.valores.valorSismoContenido = ((this.homeData.valores.valorSismoContenido * uf) / 12)
        this.homeData.valores.valorRoboContenido = ((this.homeData.valores.valorRoboContenido * uf) / 12)
        // this.homeData.valores.valorIncendioEstructura = ((this.homeData.valores.valorIncendioEstructura * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorIncendioContenido = ((this.homeData.valores.valorIncendioContenido * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorSismoEstructura = ((this.homeData.valores.valorSismoEstructura * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorSismoContenido = ((this.homeData.valores.valorSismoContenido * uf) / 12) * this.homeData.discount.value;
        // this.homeData.valores.valorRoboContenido = ((this.homeData.valores.valorRoboContenido * uf) / 12) * this.homeData.discount.value;

        this.homeData.priceUF = (Number(results.total / 12)) * this.homeData.discount.value;
      } else {
        this.homeData.valores.valorIncendioEstructura = options.coverages.find(cov => cov.coverage == 'building_fire')?.coveragePrice ?? 0
        this.homeData.valores.valorIncendioContenido = options.coverages.find(cov => cov.coverage == 'content_fire')?.coveragePrice ?? 0
        this.homeData.valores.valorSismoEstructura = options.coverages.find(cov => cov.coverage == 'building_earthquake')?.coveragePrice ?? 0
        this.homeData.valores.valorSismoContenido = options.coverages.find(cov => cov.coverage == 'content_earthquake')?.coveragePrice ?? 0
        this.homeData.valores.valorRoboContenido = options.coverages.find(cov => cov.coverage == 'content_theft')?.coveragePrice ?? 0

        this.homeData.valores.valorIncendioEstructuraUF = this.homeData.valores.valorIncendioEstructura / 12 // Valores Uf 
        this.homeData.valores.valorIncendioContenidoUF = this.homeData.valores.valorIncendioContenido / 12 // Valores Uf 
        this.homeData.valores.valorSismoEstructuraUF = this.homeData.valores.valorSismoEstructura / 12 // Valores Uf 
        this.homeData.valores.valorSismoContenidoUF = this.homeData.valores.valorSismoContenido / 12 // Valores Uf 
        this.homeData.valores.valorRoboContenidoUF = this.homeData.valores.valorRoboContenido / 12 // Valores Uf 

        // Here we capture the value for each coverage
        // And the output is the value on CLP
        this.homeData.valores.valorIncendioEstructura = (this.homeData.valores.valorIncendioEstructura * uf) / 12
        this.homeData.valores.valorIncendioContenido = (this.homeData.valores.valorIncendioContenido * uf) / 12
        this.homeData.valores.valorSismoEstructura = (this.homeData.valores.valorSismoEstructura * uf) / 12
        this.homeData.valores.valorSismoContenido = (this.homeData.valores.valorSismoContenido * uf) / 12
        this.homeData.valores.valorRoboContenido = (this.homeData.valores.valorRoboContenido * uf) / 12

        this.homeData.priceUF = Number(results.total / 12)
      }

      this.homeData.priceCLP = (this.homeData.priceUF * uf)
    },
    handleScroll() {
      // const footer = this.$el;
      // const footerOffset = footer.offsetTop;
      // const scrollTop = window.scrollY || window.pageYOffset;

      // if (scrollTop > footerOffset) {
      //   this.isSticky = true;
      // } else {
      //   this.isSticky = false;
      // }
    },
    formatDiscount() {
      if (this.homeData.couponId) {
        this.homeData.couponId = this.homeData.couponId.replace(/\s+/g, '').toUpperCase();
      }
    },
    async SendCouponID() {

      if (this.homeData.couponId == 'PR0M010H06VRFUIL') {
        this.homeData.discount.value = 0.9;
      } else if (this.homeData.couponId == 'PR0M015H06VRFUIL') {
        this.homeData.discount.value = 0.85;
      } else {
        this.homeData.discount.value = 1;
      }

      this.homeData.discount.active = true;

      if (this.homeData.couponId == 'PR0M010H06VRFUIL' || this.homeData.couponId == 'PR0M015H06VRFUIL') {
        this.homeData.discount.active == true;
        this.calculatePrice('no', 'no')
        swal({
          title: "Código válido!",
          text: "Descuento aplicado correctamente.",
          icon: "success",
          button: "Ok",
        });
      } else {
        this.homeData.discount.active = false;
        this.homeData.discount.value = 1;
        this.homeData.couponId = null;
        this.calculatePrice('no', 'no')
        swal({
          title: "Código inválido!",
          text: "Ingrese un código de descuento válido.",
          icon: "error",
          button: "Ok",
        });
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.textoSub {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
  max-width: 300px;
  margin: auto;
}

.bluebox-recommend {
  // height: 80px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(79, 116, 227, 0.08);
  padding: 2%;
}

.desc-parrafo-mb {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #4F74E3;
  justify-content: center;
  margin-top: 5%;
}

.labelValorUF {
  color: var(--AD-Texto-1, #333);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.5px;
  letter-spacing: -0.16px;
}

.butonMetros {
  border-radius: 6px;
  border: 1px solid #D6D6D6;
  background: #FFF;
  max-width: 174px;
  max-height: 39px;
}

.labelTituloInfo {
  color: var(--AD-Primario-1, #2D387C);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.text-tittle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.text-tittle-coberturas {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  padding-top: 7%;
  color: #2D387C;
}

.div-uf {
  margin: 10px;
  padding: 9px 33px;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #7b7979;
  background: #FFF;
  margin-right: 4%;
  margin-left: 40%;
  height: 3%;
  display: flex;
  width: 232px;
  height: 48px;
}

.slider {
  display: flex;
  width: 539px;
  height: 7px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  padding-right: 25%;
  padding-left: 25%;
}

.box-conteiner1 {
  margin-top: 5%;
  padding-right: 12%;
  background-color: rgba(218, 220, 251, 0.46);
  box-shadow: none !important;
}

.text-content-uf-mobile {
  color: var(--AD-Texto-2, #4F74E3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 15%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9F9FF;
  /* Color de fondo */
  opacity: 0.56;
  /* Opacidad del fondo */
  z-index: -1;
  /* Coloca el fondo detrás del contenido */
}

.imagen-arrow {
  position: absolute;
  right: 0;
  opacity: none;
}

// .elipse{
//   height: 100%;
//   margin-left: 100%;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   top: 0;
// }

// .elipse{
//   height: 10%;
//   margin-left: 60%;
//   position: absolute;
//   top: 0;
// }

.purple-image {
  margin-right: 2%;
  width: 10%;
  padding-left: 1.5%;
  padding-top: 1.5%;
  margin-left: 2%;
  margin-top: 1%;
  background: var(--AD-Secundario-1, #4F74E3);
  border-radius: 6px;
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  position: relative;
}

.imagen {
  width: 26.922px;
  height: 19.772px;
}

.text-subTittle1 {
  color: var(--AD-Primario-1, #070783);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-left: 4%;
}

.text-subTittle1-Robo {
  color: var(--AD-Primario-1, #070783);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-left: 4%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.text-content1 {
  color: var(--AD-Texto-1, #333);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 15%;

}

.box-incluido1 {
  color: var(--AD-Secundario-1, #4F74E3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  margin-left: 16%;
  margin-top: 2%;
  padding-bottom: 5%;
}

.valor-agregado {
  margin-left: 18%;
  margin-top: 5%;
  color: var(--AD-Primario-1, #2D387C);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.checkbox {
  margin-left: 18%;
  margin-bottom: -10%;
  margin-right: -15%;
}


.box-incluir {
  color: var(--AD-Secundario-1, #4F74E3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  margin-left: 16%;
  margin-top: 2%;
  padding-bottom: 5%;
}

.button-aplicar {
  width: 70px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143.34%;
  color: white;
  text-transform: capitalize;
}

.labelDescuento {
  width: 30%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.tu-plan {
  background-color: white;
  padding-bottom: 5%;
  padding-left: 5%;
  color: var(--AD-Primario-1, #2D387C);
  font-size: 16px;
  font-weight: 700;
  margin-top: -40%;
  padding-top: 8%;
}

.button-continuar {
  color: white;
  display: flex;
  width: 169px;
  height: 43px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.button-ayuda {
  display: flex;
  width: 99px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--AD-Primario-1, #2D387C);
  text-transform: none !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
}

@media only screen and (max-width: 800px) {
  .text-tittle {
    color: var(--AD-Primario-1, #2D387C);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }


  .text-tittle-coberturas {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.01em;
    padding-top: 7%;
    color: #2D387C;
  }

  .div-uf {
    margin: 7%;
    margin-left: 30%;
    margin-right: 5%;

    border-radius: 6px;
    border: 1px solid #D6D6D6;
    background: #FFF;

    display: flex;
    width: 145px;
    padding: 9px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .number-uf {
    color: var(--AD-Texto-1, #333);
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 106.667% */
    margin-top: 30%;
  }

  .slider {
    display: flex;
    width: 539px;
    height: 7px;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    padding-right: 25%;
    padding-left: 25%;

    margin-top: -5%;
    margin-bottom: 5%;

  }

}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>